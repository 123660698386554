import { reducerWithInitialState } from 'typescript-fsa-reducers'
import {
    overwriteContact,
    saveContact,
    setContactFetchingFinish,
    setContactFetchingStart,
    setDirty
} from './contact.actions'
import { Contact } from '~store/contact/contact.interfaces'

export interface ContactState {
    contact: Contact
    fetching: boolean
    dirty: boolean
}

const emptyContact: Contact = {
    title: "",
    subtitle: "",
    name: "",
    street: "",
    zip: "",
    city: "",
    phone: "",
    mail: "",
    telegram: "",
    instagram: "",
    facebook: ""
}

const INITIAL_STATE: ContactState = {
    contact: emptyContact,
    fetching: true,
    dirty: false
}

export const ContactReducer = reducerWithInitialState(INITIAL_STATE)
    .case(overwriteContact, (state, payload) => {
        return ({
            ...state,
            contact: payload
        })
    })
    .case(setContactFetchingStart, (state) => {
        return {
            ...state,
            fetching: true
        }
    })
    .case(setContactFetchingFinish, (state) => {
        return {
            ...state,
            fetching: false
        }
    })
    .case(setDirty, (state) => {
        return {
            ...state,
            dirty: true
        }
    })
    .case(saveContact, state => {
        return {
            ...state,
            dirty: false
        }
    })