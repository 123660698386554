import React from 'react'
import TextBlock from '../../components/TextBlock/TextBlock'
import { imgBaseUrl } from '~data/config'
import { Profile } from '~store/profile/profile.interfaces'

interface ProfileProps {
    profile: Profile
    hash?: string
}

const Profile: React.FC<ProfileProps> = ({
  profile,
  hash
}) => {
    return (
        <div id={'profil'}>
            <TextBlock
                title={profile.title}
                imagepath={ imgBaseUrl + '/profile/profil.jpg' + (hash ? ('?' + hash) : '') }
                align={'left'}
                paddingTopMobile={true}
            >
                <span>
                        <table className={'profile_table'}>
                            <tbody>
                                {profile.curicula.sort((a, b) => b.year - a.year).map((curicula) => {
                                    return (
                                        <tr key={curicula.year}>
                                            <td className={'grid-2--bold'}>{curicula.year}</td>
                                            <td>
                                              {
                                                curicula.event.split('\n').map((item, index) => {
                                                  return <p key={index}>{item}</p>
                                                })
                                              }
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        <br />
                    {profile.freetext.sort((a, b) => a.index - b.index).map((text, index) => {
                        return (
                            <div key={index}>
                                {text.content}<br/><br/>
                            </div>
                        )
                    })}
                    </span>
            </TextBlock>
        </div>
    )
}

export default Profile