const colors = {
  colorGold: '#ffe38f',
  colorDarkGold: '#d5b149',

  colorVeryDarkGrey: '#656565',
  colorDarkGrey: '#838383',
  colorGrey: '#b3b3b3',
  colorSemiLightGrey: '#DDDDDD',
  colorLightGrey: '#e7e7e7',
  colorWhite: '#ffffff',

  colorSignal: '#CB2121'
}

const breakPoints = {
  breakMax: 'screen and (max-width: 1080px)',
  breakMobile: 'screen and (max-width: 900px)',
  breakVerySmall: 'screen and (max-width: 600px)'
}

const theme = {
  ...colors,
  ...breakPoints
}

export default theme