import { takeEvery, put } from 'redux-saga/effects'
import {
  changePassword,
  checkToken,
  loginAttempt,
  loginFailed,
  loginSuccess,
  loginUserBlocked,
} from './user.actions'
import * as path from 'path'
import { baseUrl } from '~data/config'

const userBaseUrl = path.join(baseUrl, 'user')

function * loginAttemptSaga (action: any) {
  const username = action.payload.username
  const password = action.payload.password

  const apiJson = yield fetch('https://' + userBaseUrl + '/login/' + username + '/' + password)
  const res = yield apiJson.json()

  if (res.status === 'SUCCESS') {
    yield localStorage.setItem('token', res.token)
    yield put(loginSuccess({ username: username, role: res.role }))
  } else if (res.status === 'VALIDATION_FAILED') {
    yield put(loginFailed())
  } else if (res.status === 'USER_BLOCKED') {
    yield put(loginUserBlocked())
  }
}

function * checkTokenSaga () {
  const token = yield localStorage.getItem('token')
  if (token) {
    const apiJson = yield fetch('https://' + userBaseUrl + '/check-token', {
      method: 'GET',
      headers: { token: token },
    })
    const response = yield apiJson.json()
    yield put(loginSuccess({ username: response.username, role: response.role }))
  }
}

function * changePasswordSaga (action) {
  const newPassword = action.payload
  const token = yield localStorage.getItem('token')
  const body = { newPassword: newPassword }
  if (token) {
    yield fetch('https://' + userBaseUrl + '/changePassword', {
      method: 'POST',
      headers: {
        token: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
  }
}

export function * watchUserSagas () {
  yield takeEvery(loginAttempt, loginAttemptSaga)
  yield takeEvery(checkToken, checkTokenSaga)
  yield takeEvery(changePassword, changePasswordSaga)
}
