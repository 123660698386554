import React, { useState } from 'react'
import {
  BackendContent,
  BackendHeader, BackendRight,
  BackendTitle,
  BoxTitle,
  EditBox,
  PreviewBox,
} from '~screens/Backend/BackendStyling'
import { useDispatch, useSelector } from 'react-redux'
import { getProfile, getProfileDirty } from '~store/profile/profile.selectors'
import Profile from '~screens/Home/Profile'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from '@material-ui/core'
import { overwriteProfile, replaceProfileImage, saveProfile, setProfileDirty } from '~store/profile/profile.actions'
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'

const BackendProfil: React.FC = () => {
  const profile = useSelector(getProfile)
  const dispatch = useDispatch()
  const dirty = useSelector(getProfileDirty)
  const [yearToAdd, setYearToAdd] = useState(2020)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [img, setImg] = useState(new Blob())
  const [hash, setHash] = useState(Date.now())

  const changeCuriculaByYear = (event) => {
    const oldCuricula = profile.curicula.filter(e => e.year != parseInt(event.target.id))
    dispatch(overwriteProfile({
      ...profile,
      curicula: [
        ...oldCuricula,
        {
          year: event.target.id,
          event: event.target.value
        }
      ]
    }))
    dispatch(setProfileDirty())
  }

  const deleteCuriculaByYear = (year) => {
    const oldCuricula = profile.curicula.filter(e => e.year !== year)
    dispatch(overwriteProfile({
      ...profile,
      curicula: [...oldCuricula]
    }))
    dispatch(setProfileDirty())
  }

  const addCuriculaByYear = () => {
    if (profile.curicula.filter(e => e.year == yearToAdd).length > 0) {
      setDialogOpen(true)
    } else {
      dispatch(overwriteProfile({
        ...profile,
        curicula: [
          ...profile.curicula,
          {
            year: yearToAdd,
            event: ''
          }
        ]
      }))
      dispatch(setProfileDirty())
    }
  }

  const changeTextByIndex = (event) => {
    const oldTexts = profile.freetext.filter(e => e.index != parseInt(event.target.id))
    dispatch(overwriteProfile({
      ...profile,
      freetext: [
        ...oldTexts,
        {
          index: parseInt(event.target.id),
          content: event.target.value
        }
      ]
    }))
    dispatch(setProfileDirty())
  }

  const deleteTextByIndex = (index) => {
    const oldTexts = profile.freetext.filter(e => e.index !== index)
    dispatch(overwriteProfile({
      ...profile,
      freetext: [...oldTexts]
    }))
    dispatch(setProfileDirty())
  }

  const addText = () => {
    const nexIndex = profile.freetext.sort((a, b) => b.index - a.index)[0].index + 1 || 1
    dispatch(overwriteProfile({
      ...profile,
      freetext: [
        ...profile.freetext,
        {
          index: nexIndex,
          content: ''
        }
      ]
    }))
    dispatch(setProfileDirty())
  }

  const save = () => {
    dispatch(saveProfile(profile))
    if (img.size > 0) {
      const data = new FormData()
      data.append('img', img)
      dispatch(replaceProfileImage(data))
      setTimeout(() => setHash(Date.now()), 100)
    }
  }

  const handleFileChange = (event) => {
    setImg(event.target.files[0])
    dispatch(setProfileDirty())
  }

  const closeDialog = () => setDialogOpen(false)

  return (
    <BackendRight>
      <BackendHeader>
        <BackendTitle>Profil</BackendTitle>
        <Button
          variant="contained"
          color="primary"
          style={{ width: 300, height: 50, marginTop: 10, marginRight: 30 }}
          onClick={save}
          disabled={!dirty}
        >
          Speichern
        </Button>
      </BackendHeader>

      <BackendContent>
        <EditBox>
          <BoxTitle>Allgemeines</BoxTitle>
          <table>
            <tbody>
            <tr>
              <td>Name:</td>
              <td>
                <TextField
                  value={profile.title}
                  variant='outlined'
                  onChange={(event) => {
                    dispatch(overwriteProfile({ ...profile, title: event.target.value }))
                    dispatch(setProfileDirty())
                  }}
                  style={{ width: 400 }}
                />
              </td>
            </tr>
            </tbody>
          </table>

          <br /><br />

          <BoxTitle>Lebenslauf</BoxTitle>
          <table>
            <tbody>
            {
              profile.curicula.sort((a, b) => b.year - a.year).map((cur, index) => {
                return (
                  <tr key={index}>
                    <td>{cur.year}</td>
                    <td>
                      <TextField
                        value={cur.event}
                        id={String(cur.year)}
                        multiline
                        variant='outlined'
                        onChange={changeCuriculaByYear}
                        style={{ width: 600 }}
                      />
                    </td>
                    <td>
                      <IconButton onClick={() => deleteCuriculaByYear(cur.year)}>
                        <DeleteIcon />
                      </IconButton>
                    </td>
                  </tr>
                )
              })
            }
            <tr>
              <td />
              <td style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: 200, marginTop: 20, display: 'inline-block' }}>Jahr hinzufügen:</div>
                <TextField
                  value={String(yearToAdd)}
                  variant='outlined'
                  onChange={(event) => setYearToAdd(parseInt(event.target.value))}
                  style={{ width: 200, marginRight: 20, marginTop: 20 }}
                />

                <Button
                  variant="contained"
                  color="primary"
                  style={{ width: 180, marginTop: 20 }}
                  onClick={addCuriculaByYear}
                >
                  Hinzufügen
                </Button>
              </td>
            </tr>
            </tbody>
          </table>

          <Dialog
            open={dialogOpen}
            onClose={closeDialog}
          >
            <DialogTitle>{"Jahr existiert bereits"}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Ein Lebenslaufeintrag aus diesem Jahr existiert bereits.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDialog} color="primary">
                Okay
              </Button>
            </DialogActions>
          </Dialog>

          <br /><br />

          <BoxTitle>Freitext</BoxTitle>

          <table>
            <tbody>
            {
              profile.freetext.sort((a, b) => a.index - b.index).map(text => {
                return (
                  <tr key={text.index}>
                    <td>
                      <TextField
                        value={text.content}
                        id={String(text.index)}
                        multiline
                        variant='outlined'
                        onChange={changeTextByIndex}
                        style={{ width: 600 }}
                      />
                    </td>
                    <td>
                      <IconButton onClick={() => deleteTextByIndex(text.index)}>
                        <DeleteIcon />
                      </IconButton>
                    </td>
                  </tr>
                )
              })
            }
            <tr>
              <td>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ width: 200, marginTop: 20 }}
                  onClick={addText}
                >
                  Absatz hinzufügen
                </Button>
              </td>
            </tr>
            </tbody>
          </table>

          <br /><br />

          <BoxTitle>Bild ersetzen</BoxTitle>

          <input type='file' name='file' onChange={handleFileChange} accept='image/*' />
          <span>Auflösung für optimale Darstellung: 900x600 Pixel</span>

        </EditBox>

        <BackendTitle>Vorschau</BackendTitle>
        <PreviewBox>
          <Profile profile={profile} hash={String(hash)}/>
        </PreviewBox>
      </BackendContent>
    </BackendRight>
  )
}

export default BackendProfil
