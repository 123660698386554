import actionCreatorFactory from 'typescript-fsa'
import { Profile } from '~store/profile/profile.interfaces'

const actionCreator = actionCreatorFactory()

export const fetchProfile = actionCreator<void>('PROFILE_FETCH')
export const overwriteProfile = actionCreator<Profile>('PROFILE_OVERWRITE')
export const setProfileFetchingStart = actionCreator<void>('PROFILE_SET_FETCHING_START')
export const setProfileFetchingFinish = actionCreator<void>('PROFILE_SET_FETCHING_FINISH')
export const saveProfile = actionCreator<Profile>('PROFILE_SAVE')
export const setProfileDirty = actionCreator<void>('PROJECT_SET_DIRTY')
export const replaceProfileImage = actionCreator<FormData>('PROJECT_REPLACE_IMAGE')