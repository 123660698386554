import { baseUrl } from '~data/config'
import * as path from 'path'
import { put, takeEvery } from 'redux-saga/effects'
import {
  addElement,
  changeElement,
  changePriceTitle,
  deleteElement,
  fetchPrices, moveDownElement, moveUpElement,
  overWritePrices, sendPriceRequest
} from '~store/prices/prices.actions'

const pricesBaseUrl = path.join(baseUrl, 'prices')

function * fetchPricesSaga () {
  const res = yield fetch('https://' + path.join(pricesBaseUrl, 'getPrices'))
  const json = yield res.json()
  yield put(overWritePrices(json))
}

function * changePriceTitleSaga(action) {
  let token = yield localStorage.getItem('token')
  yield fetch('https://' + path.join(pricesBaseUrl, 'changeTitle', String(action.payload.segment)), {
    method: 'POST',
    headers: {
      'token': token,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(action.payload)
  })
}

function * addElementSaga(action) {
  let token = yield localStorage.getItem('token')
  yield fetch('https://' + path.join(pricesBaseUrl, 'addElement', String(action.payload.segment)), {
    method: 'POST',
    headers: {
      'token': token,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(action.payload.element)
  })
  yield put(fetchPrices())
}

function * changeElementSaga(action) {
  let token = yield localStorage.getItem('token')
  yield fetch('https://' + path.join(pricesBaseUrl, 'changeElement', String(action.payload.segment)), {
    method: 'POST',
    headers: {
      'token': token,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(action.payload.element)
  })
  yield put(fetchPrices())
}

function * deleteElementSaga(action) {
  let token = yield localStorage.getItem('token')
  yield fetch('https://' + path.join(pricesBaseUrl, 'deleteElement', String(action.payload.segment)), {
    method: 'POST',
    headers: {
      'token': token,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(action.payload.element)
  })
  yield put(fetchPrices())
}

function * moveUpElementSaga(action) {
  let token = yield localStorage.getItem('token')
  yield fetch('https://' + path.join(pricesBaseUrl, 'moveUp', String(action.payload.segment)), {
    method: 'POST',
    headers: {
      'token': token,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(action.payload.element)
  })
  yield put(fetchPrices())
}

function * moveDownElementSaga(action) {
  let token = yield localStorage.getItem('token')
  yield fetch('https://' + path.join(pricesBaseUrl, 'moveDown', String(action.payload.segment)), {
    method: 'POST',
    headers: {
      'token': token,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(action.payload.element)
  })
  yield put(fetchPrices())
}

function * sendPriceRequestSaga(action) {
  let token = yield localStorage.getItem('token')
  yield fetch('https://' + path.join(pricesBaseUrl, 'sendMail'), {
    method: 'POST',
    headers: {
      'token': token,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(action.payload)
  })
}

export function* watchPriceSagas() {
  yield takeEvery(fetchPrices, fetchPricesSaga)
  yield takeEvery(changePriceTitle, changePriceTitleSaga)
  yield takeEvery(addElement, addElementSaga)
  yield takeEvery(changeElement, changeElementSaga)
  yield takeEvery(deleteElement, deleteElementSaga)
  yield takeEvery(moveUpElement, moveUpElementSaga)
  yield takeEvery(moveDownElement, moveDownElementSaga)
  yield takeEvery(sendPriceRequest, sendPriceRequestSaga)
}