import React, { useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core'
import PreisAccSingle from '~screens/Preise/PreisAccSingle'
import { getPriceTotal, getSelected } from '~store/prices/prices.selectors'
import { useDispatch, useSelector } from 'react-redux'
import { sendPriceRequest } from '~store/prices/prices.actions'

const Preise: React.FC = () => {
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')
  const selected = useSelector(getSelected)
  const price = useSelector(getPriceTotal)
  const dispatch = useDispatch()
  const [modalOpen, setModalOpen] = useState(false)

  const changeEmail = (event) => {
    setEmail(event.target.value)
    if (!event.target.value.match('.+@.+\\..+')) {
      setEmailError('Das ist keine valide Email Adresse!')
    } else {
      setEmailError('')
    }
  }

  const sendMail = () => {
    dispatch(sendPriceRequest({
      email: email,
      prices: selected
    }))
    setModalOpen(true)
  }

  return (
    <div className='container color-dark-grey'>
      <div className='container--small'>
          <div className='price-container'>
              <h1 className="font--h1">Preise</h1>

              <div className='padding-bottom--small'>
                  Hier finden Sie eine unverbindliche Preisliste für bestimmte Arbeitsabläufe. Bitte beachten Sie, dass es sich
                  um pauschalisierte Preise handelt, die je nach Arbeitsaufwand und Kombination variieren können. Ein individuelles
                  Angebot, inklusive einer Beratung für Ihr Projekt erhalten Sie für 35€. Ein Nutzungsrecht für Ihr Design erhalten
                  Sie ab einem Faktor von 0,2, je nach Art und Umfang.
              </div>
          </div>
      </div>
      <div className='container'>
        <div className='price-container'>
          <PreisAccSingle segment={0} />
          <PreisAccSingle segment={1} />
          <PreisAccSingle segment={2} />
        </div>
      </div>

      <div className='container--small'>
        <div className='price-container'>
          <div className='styled-price-list'>

            <div className='uvp-container'>Unverbindliche Preisempfehlung: {price.toFixed(2)} €</div>

            <div className='submit-stuff'>
              <TextField
                value={email}
                error={emailError.length !== 0}
                label='Ihre Emailadresse'
                onChange={changeEmail}
                variant='outlined'
                style={{ minHeight: 60 }}
                helperText={emailError}
              />
              <Button
                variant="contained"
                color="primary"
                disabled={email.length === 0 || selected.length === 0 || emailError.length !== 0}
                onClick={sendMail}
                style={{ minHeight: 40, marginBottom: 60 }}
              >
                Anfrage absenden
              </Button>
            </div>
          </div>
        </div>

        <Dialog
          open={modalOpen}
          onClose={() => setModalOpen(false)}
        >
          <DialogTitle>
            Abgesendet
          </DialogTitle>
          <DialogContent>
            Anfrage erfolgreich abgesendet
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setModalOpen(false)} color="primary">
              Okay
            </Button>
          </DialogActions>
        </Dialog>
      </div>

    </div>
  )
}

export default Preise
