import React from 'react'
import { BackendContent, BackendHeader, BackendRight, BackendTitle } from '~screens/Backend/BackendStyling'
import PreisSegment from '~screens/Backend/Screens/Preise/PreisSegment'

const BackendPrices: React.FC = () => {
  return (
    <BackendRight>
      <BackendHeader>
        <BackendTitle>Preise</BackendTitle>
      </BackendHeader>

      <BackendContent>
        <PreisSegment segment={0} />
        <PreisSegment segment={1} />
        <PreisSegment segment={2} />
      </BackendContent>
    </BackendRight>
  )
}

export default BackendPrices
