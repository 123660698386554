import React, { useState } from 'react'
import { BackendContent, BackendHeader, BackendRight, BackendTitle, EditBox } from '~screens/Backend/BackendStyling'
import { useDispatch, useSelector } from 'react-redux'
import { getImpressum, getImpressumFetching } from '~store/html/html.selectors'
import { Node } from 'slate'
import { Button } from '@material-ui/core'
import { saveImpressum } from '~store/html/html.actions'
import RTE from '~screens/Backend/Components/RTE'
import Progress from '~components/Progress/Progress'

const BackendImpressum: React.FC = () => {
  const impressum = useSelector(getImpressum)
  const impressumFetching = useSelector(getImpressumFetching)
  const [content, setContent] = useState<Node[]>(impressum)
  const [dirty, setDirty] = useState(false)
  const dispatch = useDispatch()

  return (
    <BackendRight>
      <BackendHeader>
        <BackendTitle>Impressum</BackendTitle>
        <Button
          variant="contained"
          color="primary"
          style={{ width: 300, height: 50, marginTop: 10, marginRight: 30 }}
          disabled={!dirty}
          onClick={() => {
            setDirty(false)
            dispatch(saveImpressum(content))
          }}
        >
          Speichern
        </Button>
      </BackendHeader>

      <BackendContent>
        <EditBox>
          {impressumFetching ? <Progress /> : <RTE content={content} setContent={setContent} setDirty={setDirty} />}
        </EditBox>
      </BackendContent>
    </BackendRight>
  )
}

export default BackendImpressum
