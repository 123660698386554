import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter as Router} from "react-router-dom";
import {Route, Switch} from "react-router";
import createSagaMiddleware from 'redux-saga'
import { composeWithDevTools } from 'redux-devtools-extension'
import { applyMiddleware, createStore } from 'redux'
import { rootReducer } from '~store/root.reducer'
import rootSaga from './store/root.sagas'
import { Provider } from 'react-redux'
import { ThemeProvider } from '@material-ui/core/styles';

import Header from "./components/Header/header";
import Home from "./screens/Home/Home";
import Footer from "./components/Footer/footer";
import BackToTop from "./components/BackToTop/backtotop";
import Impressum from "./screens/Impressum/Impressum";
import Datenschutz from "./screens/Datenschutz/Datenschutz";
import InitialFetch from './components/InitialFetch/InitialFetch'

import './Index.styl'
import { createMuiTheme } from '@material-ui/core'
import Preise from '~screens/Preise/Preise'
import Backend from '~screens/Backend/Backend'
import LoginRoute from '~components/LoginRoute/LoginRoute'
import Login from '~screens/Login/Login'
import PrivateRoute from '~components/PrivateRoute/PrivateRoute'
import AGB from '~screens/AGB/AGB'

export const theme = createMuiTheme({
    typography: {
        fontFamily: 'Barlow Condensed',
        fontSize: window.innerWidth > 3000 ? (16 * 1.5) : 16
    },
    palette: {
        primary: {
            main: '#d5b149',
            dark: '#ffe38f',
            contrastText: '#656565'
        },
    }
});

const App = () => {
    const sagaMiddleware = createSagaMiddleware()
    const store = createStore(
        rootReducer,
        composeWithDevTools(
            applyMiddleware(sagaMiddleware)
        )
    )
    sagaMiddleware.run(rootSaga)

    return (
        <Provider store={store}>
            <Router>
                <ThemeProvider theme={theme}>
                    <InitialFetch />

                    <Switch>
                        <LoginRoute path='/login'>
                            <Login />
                        </LoginRoute>

                        <PrivateRoute path='/backend'>
                            <Backend />
                        </PrivateRoute>

                        <Route path='/' id='start'>
                            <Header />
                            <Switch>
                                <Route path='/impressum' component={Impressum} />
                                <Route path='/datenschutz' component={Datenschutz} />
                                <Route path='/agb' component={AGB} />
                                <Route path='/preise' component={Preise} />
                                <Route path='/:scroll' component={Home} />
                                <Route path='/' component={Home} />
                            </Switch>
                            <Footer />
                            <BackToTop />
                        </Route>
                    </Switch>

                </ThemeProvider>
            </Router>
        </Provider>
    );
};

ReactDOM.render(<App />, window.document.getElementById('root'));