import React, { useState } from 'react'
import {
  AccordionButton,
  BoxTitle,
  EditBox,
  ElementButton,
  SubHeader, SubHeaderLine,
  TypeContainer
} from '~screens/Backend/BackendStyling'
import { useDispatch, useSelector } from 'react-redux'
import { getPrices } from '~store/prices/prices.selectors'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField } from '@material-ui/core'
import {
  addElement,
  changeElement,
  changePriceTitle,
  deleteElement, moveDownElement, moveUpElement,
  overWritePrices
} from '~store/prices/prices.actions'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { Price, PriceElement, Subheader } from '~store/prices/prices.interfaces'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

interface PreisSegmentProps {
  segment: number
}

const emptyPrice: Price = {
  price: 0,
  title: '',
  text: ''
}

const emptySubheader: Subheader = {
  text: ''
}

const emptyElement: PriceElement = {
  id: 0,
  type: 'price',
  data: emptyPrice
}

const PreisSegment: React.FC<PreisSegmentProps> = ({segment}) => {
  const prices = useSelector(getPrices)
  const dispatch = useDispatch()
  const [dirty, setDirty] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [newEl, setNewEl] = useState(false)
  const [currentEl, setCurrentEl] = useState(emptyElement)

  const renderDialogTitle = () => {
    switch (currentEl.type) {
      case 'price': return 'Preis'
      case 'amount': return 'Anzahl'
      case 'subheader': return 'Überschrift'
      case 'subsubheader': return 'Subtitle'
    }
  }

  return (
    <EditBox>
      <BoxTitle>
        {prices[segment].title}
        <IconButton
          onClick={() => {
            setModalOpen(true)
            setNewEl(true)
            setCurrentEl(emptyElement)
          }}
        >
          <AddCircleIcon />
        </IconButton>
      </BoxTitle>

      <TextField
        value={prices[segment].title}
        variant='outlined'
        label='Titel'
        onChange={(event) => {
          const newPrices = {...prices}
          newPrices[segment].title = event.target.value
          dispatch(overWritePrices(newPrices))
          setDirty(true)
        }}
        style={{ width: 400 }}
      />
      <Button
        variant='contained'
        color='primary'
        disabled={!dirty}
        onClick={() => {
          dispatch(changePriceTitle({ segment: segment, title: prices[segment].title }))
          setDirty(false)
        }}
        style={{ height: 58, marginLeft: 10 }}
      >
        Speichern
      </Button>

      {
        prices[segment].prices.sort((a, b) => a.id - b.id).map((el, index) => {
          return (
            <ElementButton key={index}>
              <div>
                {
                  el.type === 'price' || el.type === 'amount' ?
                    <div>
                      <div style={{ fontWeight: 'bold' }}>{el.data.title}</div>
                      <div>{el.data.text}</div>
                      <div>Ab {el.data.price.toFixed(2)} €</div>
                    </div> : ( el.type === 'subheader' ?
                      <SubHeader>
                        <span>{el.data.text}</span>
                        <SubHeaderLine />
                      </SubHeader> :
                      <div style={{ fontWeight: 'bold' }}>{el.data.text}</div>
                    )

                }
              </div>
              <div>
                <IconButton
                  onClick={() => dispatch(moveDownElement({ segment: segment, element: el }))}
                  disabled={index === 0}
                >
                  <ExpandLessIcon />
                </IconButton>
              </div>
              <div>
                <IconButton
                  onClick={() => dispatch(moveUpElement({ segment: segment, element: el }))}
                  disabled={prices[segment].prices.length === index + 1}
                >
                  <ExpandMoreIcon />
                </IconButton>
              </div>
              <div>
                <IconButton onClick={() => {
                  setCurrentEl(el)
                  setNewEl(false)
                  setModalOpen(true)
                }}>
                  <EditIcon />
                </IconButton>
              </div>
              <div>
                <IconButton
                  onClick={() => {
                    dispatch(deleteElement({
                      segment: segment,
                      element: el
                    }))
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            </ElementButton>
          )
        })
      }

      <Dialog
        open={modalOpen}
        onClose={() => setModalOpen(false)}
      >
        <DialogTitle>
          {renderDialogTitle()}
          {newEl ? ' hinzufügen' : ' bearbeiten'}
        </DialogTitle>
        <DialogContent>
          {
            newEl &&
              <TypeContainer>
                <span>Element-Typ:</span>
                <Button
                  variant='outlined'
                  onClick={() => setCurrentEl({ ...emptyElement })}
                >
                  Preis
                </Button>
                <Button
                    variant='outlined'
                    onClick={() => setCurrentEl({ ...emptyElement, type: 'amount' })}
                >
                    Anzahl
                </Button>
                <Button
                  variant='outlined'
                  onClick={() => setCurrentEl({ ...emptyElement, data: emptySubheader, type: 'subheader' })}
                >
                  Überschrift
                </Button>
                <Button
                    variant='outlined'
                    onClick={() => setCurrentEl({ ...emptyElement, data: emptySubheader, type: 'subsubheader' })}
                >
                    Subtitle
                </Button>
              </TypeContainer>
          }
          {
            currentEl.type === 'price' || currentEl.type === 'amount' ?
              <div>
                <TextField
                  value={(currentEl.data as Price).title}
                  variant='outlined'
                  label='Titel'
                  onChange={(event) => {
                    setCurrentEl({
                      ...currentEl,
                      data: {
                        ...currentEl.data,
                        title: event.target.value
                      }
                    })
                  }}
                  style={{ width: 400, marginBottom: 10 }}
                />
                <TextField
                  value={(currentEl.data as Price).text}
                  variant='outlined'
                  label='Text'
                  multiline
                  onChange={(event) => {
                    setCurrentEl({
                      ...currentEl,
                      data: {
                        ...currentEl.data,
                        text: event.target.value
                      }
                    })
                  }}
                  style={{ width: 400, marginBottom: 10 }}
                />
                <TextField
                  value={String((currentEl.data as Price).price)}
                  variant='outlined'
                  label='Preis'
                  type='number'
                  inputProps={{ min: "0", step: "0.01" }}
                  onChange={(event) => {
                    setCurrentEl({
                      ...currentEl,
                      data: {
                        ...currentEl.data,
                        price: parseFloat(event.target.value)
                      }
                    })
                  }}
                  style={{ width: 400, marginBottom: 10 }}
                />
              </div> :
              <div>
                <TextField
                  value={(currentEl.data as Price).text}
                  variant='outlined'
                  label='Text'
                  multiline
                  onChange={(event) => {
                    setCurrentEl({
                      ...currentEl,
                      data: {
                        ...currentEl.data,
                        text: event.target.value
                      }
                    })
                  }}
                  style={{ width: 400, marginBottom: 10 }}
                />
              </div>
          }
        </DialogContent>
        <DialogActions>
          <Button
            color='primary'
            onClick={() => {
              if (newEl) {
                dispatch(addElement({ segment: segment, element: currentEl }))
              } else {
                dispatch(changeElement({ segment: segment, element: currentEl }))
              }
              setModalOpen(false)
            }}
          >
            Speichern
          </Button>
          <Button onClick={() => setModalOpen(false)} color="primary">
            Abbrechen
          </Button>
        </DialogActions>
      </Dialog>
    </EditBox>
  )
}

export default PreisSegment
