import React, { useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleUp } from '@fortawesome/free-solid-svg-icons'
import { useDispatch, useSelector } from 'react-redux'
import { getPrices } from '~store/prices/prices.selectors'
import { Switch, TextField } from '@material-ui/core'
import { changeAmount, toggleSelected } from '~store/prices/prices.actions'

interface PreisAccSingleProps {
  segment: number
}

const PreisAccSingle: React.FC<PreisAccSingleProps> = (props) => {
  const prices = useSelector(getPrices)

  const acc1 = useRef<HTMLDivElement>(null);
  const [opened1, setOpened1] = useState(false)
  const [height1, setHeight1] = useState(0);

  const dispatch = useDispatch()

  const renderElement = (el) => {
    switch (el.type) {

      case 'price':
        return <div className='price-display padding-bottom--small'>
          <div>
            <div style={{ fontWeight: 'bold' }}>{el.data.title}</div>
            <div dangerouslySetInnerHTML={{__html: el.data.text.replace('\n','<br />')}} />
            <div>Ab {el.data.price.toFixed(2)} €</div>
          </div>
          <div>
            <Switch
              color='primary'
              checked={el.selected}
              onChange={() => dispatch(toggleSelected({ segment: props.segment, element: el }))}
            />
          </div>
        </div>

      case 'amount':
        return <div className='price-display padding-bottom--small'>
          <div>
            <div style={{ fontWeight: 'bold' }}>{el.data.title}</div>
            <div dangerouslySetInnerHTML={{__html: el.data.text.replace('\n','<br />')}} />
            <div>Ab {el.data.price.toFixed(2)} €</div>
          </div>
          <div>
            <TextField
              value={el.amount}
              type='number'
              style={{ width: 60 }}
              onChange={event => {dispatch(changeAmount({ segment: props.segment, element: el, amount: parseInt(event.target.value) }))}}
            />
          </div>
        </div>

      case 'subheader':
        return <div className='subheader padding-bottom--small' >
          <span>{el.data.text}</span>
          <div className='subheader-line' />
        </div>

      case 'subsubheader':
        return <div style={{ fontWeight: 'bold' }} className='padding-bottom--small'>{el.data.text}</div>
    }
  }

  return (
    <>
      <div
        className={opened1 ? "accordion--button accordion--button--active" : "accordion--button"}
        onClick={() => {
          setOpened1(!opened1)
          if (acc1.current) setHeight1(opened1 ? 0 : acc1.current.scrollHeight)
        }}
      >
        <span>{prices[props.segment].title}</span>
        <FontAwesomeIcon className={opened1 ? "accordion--icon" : "accordion--icon accordion--icon--rotate"} icon={faChevronCircleUp} />
      </div>
      <div ref={acc1} style={{ maxHeight: height1 }} className="panel-prices font-size-normal">
        {
          prices[props.segment].prices.sort((a, b) => a.id - b.id).map(el => {
            return (
              <div key={el.id}>
                {renderElement(el)}
              </div>
            )
          })
        }
      </div>
    </>
  )
}

export default PreisAccSingle
