import { createSelector } from 'reselect'
import { RootState } from '../root.reducer'

const selectContactState = (state: RootState) => state.contact;

export const getContact = createSelector(
    selectContactState,
    contact => contact.contact
)

export const getContactFetching = createSelector(
  selectContactState,
  contact => contact.fetching
)

export const getContactDirty = createSelector(
  selectContactState,
  contact => contact.dirty
)