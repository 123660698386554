import React from "react";

interface TextblockProps {
    title: string,
    imagepath?: string,
    align: string,
    paddingTopMobile?: boolean,
    noImage?: boolean,
    children
}

const TextBlock: React.FC<TextblockProps> = (props) => {
    let reverse = props.align === "right" ? "" : "grid-2--reverse";
    let textReverse = props.align == "right" ? "grid-2--left" : "grid-2--right";
    let paddingTopMobile = props.paddingTopMobile ? "padding-top--regular--only--mobile" : "";

    return (
        <div className={"container " + paddingTopMobile}>
            <div className={"grid-2 " + reverse}>
                <div className={textReverse + " grid-2--text"}>
                    {!props.noImage && <h2 className="text--h2">{props.title}</h2>}
                    {props.children}
                </div>
                <div className="grid-2--right">
                    {props.noImage && <h2 className="grid-2--header-only">{props.title}</h2>}
                    {!props.noImage && <img className="grid-2--image" src={'https://' + props.imagepath} alt='image' />}
                </div>
            </div>
        </div>
    );
};

export default TextBlock