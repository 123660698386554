import React, { Dispatch, SetStateAction, useState } from 'react'
import { AccordionButton, BoxTitle, EditBox } from '~screens/Backend/BackendStyling'
import { Button, IconButton, TextField } from '@material-ui/core'
import {
  changeAccordionTitle,
  deleteAccordionSlide,
  moveDownAccordion, moveUpAccordion,
  overwriteAccordion
} from '~store/accordion/accordion.actions'
import { useDispatch, useSelector } from 'react-redux'
import { getAccordion } from '~store/accordion/accordion.selectors'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import { emptyAccordionSlide } from '~screens/Backend/Screens/BackendLeistungen'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

interface LeistungsSegmentProps {
  segment: number
  setCurrentSlide: Dispatch<SetStateAction<any>>
  setOverlayOpen: Dispatch<SetStateAction<any>>
  setNewSlide: Dispatch<SetStateAction<boolean>>
}

const LeistungsSegment: React.FC<LeistungsSegmentProps> = ({
  segment,
  setCurrentSlide,
  setOverlayOpen,
  setNewSlide,

}) => {
  const leistungen = useSelector(getAccordion)
  const dispatch = useDispatch()
  const [dirty, setDirty] = useState(false)

  return (
    <EditBox>
      <BoxTitle>
        {leistungen[segment].title}
        <IconButton
          onClick={() => {
            setCurrentSlide({ segment: segment, data: emptyAccordionSlide })
            setNewSlide(true)
            setOverlayOpen(true)
          }}
        >
          <AddCircleIcon />
        </IconButton>
      </BoxTitle>

      <TextField
        value={leistungen[segment].title}
        variant='outlined'
        label='Titel'
        onChange={(event) => {
          const newAcc = {...leistungen}
          newAcc[segment].title = event.target.value
          dispatch(overwriteAccordion(newAcc))
          setDirty(true)
        }}
        style={{ width: 400 }}
      />
      <Button
        variant='contained'
        color='primary'
        disabled={!dirty}
        onClick={() => {
          dispatch(changeAccordionTitle({ id: segment, title: leistungen[segment].title }))
          setDirty(false)
        }}
        style={{ height: 58, marginLeft: 10 }}
      >
        Speichern
      </Button>

      <br /><br />
      {
        leistungen[segment].slides.sort((a, b) => a.id - b.id).map((slide, index) => {
          return (
            <AccordionButton key={slide.id}>
              <div>{slide.title}</div>
              <div>
                <IconButton
                  onClick={() => dispatch(moveDownAccordion({ segment: segment, id: slide.id }))}
                  disabled={index === 0}
                >
                  <ExpandLessIcon />
                </IconButton>
              </div>
              <div>
                <IconButton
                  onClick={() => dispatch(moveUpAccordion({ segment: segment, id: slide.id }))}
                  disabled={leistungen[segment].slides.length === index + 1}
                >
                  <ExpandMoreIcon />
                </IconButton>
              </div>
              <div>
                <IconButton onClick={() => {
                  setCurrentSlide({
                    segment: segment,
                    data: {
                      id: slide.id,
                      title: slide.title,
                      text: slide.text
                    }
                  })
                  setNewSlide(false)
                  setOverlayOpen(true)
                }}>
                  <EditIcon />
                </IconButton>
              </div>
              <div>
                <IconButton
                  onClick={() => {
                    dispatch(deleteAccordionSlide({
                      segment: segment,
                      delete: slide.id
                    }))
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            </AccordionButton>
          )
        })
      }
    </EditBox>
  )
}

export default LeistungsSegment
