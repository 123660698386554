import { baseUrl } from '~data/config'
import * as path from 'path'
import { put, takeEvery } from 'redux-saga/effects'
import {
    fetchProfile,
    overwriteProfile, replaceProfileImage,
    saveProfile,
    setProfileFetchingFinish,
    setProfileFetchingStart
} from './profile.actions'

const profileBaseUrl = path.join(baseUrl, 'profile')

function * fetchProfileSaga () {
    yield put(setProfileFetchingStart())
    const profileJson = yield fetch('https://' + path.join(profileBaseUrl, 'getProfile'))
    const res = yield profileJson.json()
    yield put(overwriteProfile(res))
    yield put(setProfileFetchingFinish())
}

function * saveProfileSaga (action) {
    let token = yield localStorage.getItem('token')
    yield fetch('https://' + path.join(profileBaseUrl, 'setProfile'), {
        method: 'POST',
        headers: {
            'token': token,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(action.payload)
    })
}

function * replaceProfileImageSaga (action) {
    let token = yield localStorage.getItem('token')
    yield fetch('https://' + path.join(profileBaseUrl, 'replaceImg'), {
        method: 'POST',
        headers: {
            'token': token,
        },
        body: action.payload
    })
}



export function* watchProfileSagas () {
    yield takeEvery(fetchProfile, fetchProfileSaga)
    yield takeEvery(saveProfile, saveProfileSaga)
    yield takeEvery(replaceProfileImage, replaceProfileImageSaga)
}