import React, { useState } from 'react'
import { Button, TextField } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { sendEmail } from '~store/contact/contact.actions'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  root: {
    backgroundColor: '#EEEEEE',
  }
})

const ContactForm: React.FC = () => {
    const [email, setEmail] = useState('')
    const [subject, setSubject] = useState('')
    const [content, setContent] = useState('')
    const [emailError, setEmailError] = useState('')
    const dispatch = useDispatch()
    const classes = useStyles()

    const changeEmail = (event) => {
        setEmail(event.target.value)
        if (!event.target.value.match('.+@.+\\..+')) {
            setEmailError('Das ist keine valide Email Adresse!')
        } else {
            setEmailError('')
        }
    }

    const changeSubject = (event) => {
        setSubject(event.target.value)
    }

    const changeContent = (event) => {
        setContent(event.target.value)
    }

    const submit = () => {
        if (emailError === '') {
            dispatch(sendEmail({
                email: email,
                subject: subject,
                content: content,
            }))
            setEmail('')
            setSubject('')
            setContent('')
        }
    }

    return (
        <div className='email-container'>
            <TextField
                value={email}
                error={emailError.length !== 0}
                label='Ihre Emailadresse'
                className={classes.root}
                onChange={changeEmail}
                variant='outlined'
                helperText={emailError}
            />
            <TextField
                value={subject}
                label='Betreff'
                className={classes.root}
                onChange={changeSubject}
                variant='outlined'
            />
            <TextField
                value={content}
                label='Inhalt'
                className={classes.root}
                multiline
                onChange={changeContent}
                rows='4'
                variant='outlined'
            />
            <span style={{ fontSize: 14 }}>
              Durch die Verwendung des Kontaktformulars akzeptieren Sie die Datenschutzerklärung.
              Ihre Daten werden nur zur Beantwortung der Anfrage erhoben und verarbeitet.
            </span>
            <Button
                variant="contained"
                color="primary"
                onClick={submit}
                disabled={email.length === 0 || subject.length === 0 || content.length === 0 || emailError !== ''}
                style={{ fontSize: window.innerWidth > 3000 ? 32 : 16 }}
            >
                Absenden
            </Button>
        </div>
    )
}

export default ContactForm