import React from 'react'
import ContactForm from '~screens/Home/ContactForm'
import { Contact } from '~store/contact/contact.interfaces'
import { IconButton } from '@material-ui/core'
import TelegramIcon from '@material-ui/icons/Telegram'
import FacebookIcon from '@material-ui/icons/Facebook'
import InstagramIcon from '@material-ui/icons/Instagram'

interface ContactProps {
    contact: Contact
}

const Contact: React.FC<ContactProps> = ({
    contact
}) => {
    return (
        <div className={'bg-grey'} id={'kontakt'}>
            <div className={"container"}>
                <div className={"grid-2 grid-2--flip-on-mobile"}>
                    <div className={"grid-2--text grid-2--left grid-2--no-padding-right move-down-slightly"}>
                        <div>
                            <span className={'grid-2--bold'}>HABEN SIE FRAGEN?</span>
                            <br /><br />
                            <span className={'grid-2--bold'}>{contact.title}</span>
                            <br />{contact.subtitle}

                            <br /><br />{contact.name}
                            <br />{contact.street}
                            <br />{contact.zip} {contact.city}

                            <br /><br /><a href={'tel:' + contact.phone} >{contact.phone}</a>
                            <br /><a href={'mailto:' + contact.mail} >{contact.mail}</a>

                            <br /><br />
                            <div className='contact-icon-container'>
                                {
                                    contact.facebook !== '' &&
                                    <IconButton href={contact.facebook}>
                                      <FacebookIcon />
                                    </IconButton>
                                }
                                {
                                    contact.instagram !== '' &&
                                    <IconButton href={contact.instagram}>
                                        <InstagramIcon />
                                    </IconButton>
                                }
                                {
                                    contact.telegram !== '' &&
                                    <IconButton href={'http://t.me/' + contact.telegram}>
                                        <TelegramIcon />
                                    </IconButton>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="grid-2--right">
                        <ContactForm />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact