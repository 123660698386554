export const ROUTES = {
  route_backend: '/backend',
  route_backend_gallery: '/backend',
  route_backend_leistungen: '/backend/leistungen',
  route_backend_profil: '/backend/profile',
  route_backend_contact: '/backend/contact',
  route_backend_prices: '/backend/prices',
  route_backend_impressum: '/backend/impressum',
  route_backend_datenschutz: '/backend/datenschutz',
  route_backend_agb: '/backend/agb',
  route_backend_settings: '/backend/settings'
}