import React from 'react';

import Slider from '../../components/Slider/Slider';
import Accordion from '../../components/Accordion/Accordion';
import { useParams } from "react-router-dom";

import profil from './profil.jpg';
import kontakt from './contact.jpg';
import SliderMobile from '../../components/Slider/SliderMobile'
import { useSelector } from 'react-redux'
import { getGallery } from '~store/gallery/gallery.selectors'
import { getAccordion } from '~store/accordion/accordion.selectors'
import { getProfile, getProfileFetching } from '~store/profile/profile.selectors'
import { getContact, getContactFetching } from '~store/contact/contact.selectors'
import Contact from './Contact'
import Profile from './Profile'
import Progress from '../../components/Progress/Progress'

const Home: React.FC = () => {
    const gallery = useSelector(getGallery)
    const accordion = useSelector(getAccordion)
    const profileFetching = useSelector(getProfileFetching)
    const profile = useSelector(getProfile)
    const contactFetching = useSelector(getContactFetching)
    const contact = useSelector(getContact)

    let { scroll } = useParams();
    if (scroll === 'leistungen' || scroll === 'profil' || scroll === 'kontakt') {
        setTimeout(() => {
            let element_to_scroll_to = document.getElementById(scroll as string);
            if (element_to_scroll_to) {
                element_to_scroll_to.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start'
                });
            }
        }, 100);
    } else {
        setTimeout(() => {
            let element_to_scroll_to = document.getElementById('root');
            if (element_to_scroll_to) {
                element_to_scroll_to.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start'
                });
            }
        }, 100);
    }

    return (
        <div>
            {gallery.fetching ? <Progress /> : <Slider gallery={gallery} />}
            {gallery.fetching ? <Progress /> : <SliderMobile gallery={gallery} />}
            {accordion.fetching ? <Progress /> : <Accordion accordion={accordion} />}
            {profileFetching ? <Progress /> : <Profile profile={profile} />}
            {contactFetching ? <Progress /> : <Contact contact={contact} />}
        </div>
    );
};

export default Home