import React from "react";
import SlateSerializer from '~components/SlateSerializer/SlateSerializer'
import { useSelector } from 'react-redux'
import { getAGB, getAgbFetching } from '~store/html/html.selectors'
import Progress from '~components/Progress/Progress'

const AGB: React.FC = () => {
  const agb = useSelector(getAGB)
  const agbFetching = useSelector(getAgbFetching)

  setTimeout(() => {
    let element_to_scroll_to = document.getElementById('root');
    if (element_to_scroll_to) {
      element_to_scroll_to.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    }
  }, 100);

  return <div className="container--small color-dark-grey rte">
    <div className='rte--box'>
      {agbFetching ? <Progress /> : <SlateSerializer nodes={agb}/>}
      <br /><br />
    </div>
  </div>;
};

export default AGB