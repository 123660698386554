import styled from '@emotion/styled'
import theme from '~style/themeVariables'
import { css } from '@emotion/core'

export const StyledBackend = styled.div`
    display: flex;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    color: ${theme.colorVeryDarkGrey};
`

export const BackendNavbar = styled.div`
    height: 100vh;
    width: 250px;
    background-color: ${theme.colorSemiLightGrey};
    display: flex;
    flex-direction: column;
`

export const BackendLogo = styled.div`
    height: 100px;
    background-color: ${theme.colorLightGrey};
    padding: 20px 50px;
    margin-bottom: 20px;
    
    img {
      width: 150px;
    }
`

export const NavButton = styled.div<{ active?: boolean }>`
  height: 50px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  padding: 0 30px;
  font-size: 18px;
  
  &:hover {
    background-color: ${theme.colorLightGrey};
    cursor: pointer;
  }
  
  & > *:first-child {
        flex-basis: 20%;
  }
  
  & span {
    margin-left: 20px;
  }
  
  ${props => css`
    font-weight: ${props.active ? 'bold' : 'initial'};
  `}
`

export const BackendRight = styled.div`
  width: 100%;
  height: 100vh;
  overflow: hidden;
`

export const BackendContent = styled.div`
  width: 100%;
  height: calc(100vh - 100px);
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
  overflow: auto;
  
  p {
    margin: 0;
  }
`

export const BackendHeader = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  padding: 20px;
  align-items: center;
  justify-content: space-between;
`

export const BackendTitle = styled.div`
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
`

export const BoxTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  padding-bottom: 20px;
`

export const EditBox = styled.div`
  width: 100%;
  background-color: ${theme.colorLightGrey};
  padding: 20px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  margin-bottom: 20px;
  
  td:first-child {
    padding-right: 40px;
  }
`

export const PreviewBox = styled.div`
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  margin: 20px 0;
`

export const Spacing = styled.div`
  height: 40px;
`

export const AccordionButton = styled.div`
  display: flex;
  align-items: center;
  
  &:hover {
    background-color: ${theme.colorGrey};
  }
  
  & div:first-of-type {
    width: 400px;
    padding-left: 20px;
  }
`

export const GalleryButton = styled.div`
  display: flex;
  align-items: center;
  
  &:hover {
    background-color: ${theme.colorGrey};
  }
  
  & div:first-of-type {
    width: 200px;
  }
  
  & div:nth-child(2) {
    width: 500px;
  }
`

export const RteButton = styled.button`
  border-radius: 3px;
  border: none;
  background-color: ${theme.colorWhite};
  font-family: "Barlow Condensed", sans-serif;
  padding: 5px 10px;
  font-size: 18px;
  margin-right: 7px;
  margin-bottom: 10px;
  box-shadow: 2px 2px 0 rgba(0, 0, 0, 0.3);
`

export const TypeContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  
  button, span {
    margin-right: 10px;
  }
`

export const ElementButton = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  padding: 15px 10px;
  
  &:hover {
    background-color: ${theme.colorGrey};
  }
  
  & div:first-of-type {
    width: 600px;
  }
`

export const SubHeader = styled.div`
  font-size: 24px;
  display: flex;
  align-items: center;
  
  span {
    margin-right: 40px;
  }
`

export const SubHeaderLine = styled.div`
  background-color: ${theme.colorDarkGrey};
  height: 2px;
  width: 100%;
`