import actionCreatorFactory from 'typescript-fsa'
import { PriceElement, Prices } from '~store/prices/prices.interfaces'

const actionCreator = actionCreatorFactory()

export const fetchPrices = actionCreator<void>('PRICES_FETCH')
export const overWritePrices = actionCreator<Prices>('PRICES_OVERWRITE')
export const changePriceTitle = actionCreator<{ segment: number, title: string }>('PRICES_CHANGE_TITLE')
export const addElement = actionCreator<{ segment: number, element: PriceElement }>('PRICES_ADD_ELEMENT')
export const changeElement = actionCreator<{ segment: number, element: PriceElement }>('PRICES_CHANGE_ELEMENT')
export const deleteElement = actionCreator<{ segment: number, element: PriceElement }>('PRICES_DELETE_ELEMENT')
export const moveUpElement = actionCreator<{ segment: number, element: PriceElement }>('PRICES_MOVE_UP')
export const moveDownElement = actionCreator<{ segment: number, element: PriceElement }>('PRICES_MOVE_DOWN')
export const toggleSelected = actionCreator<{ segment: number, element: PriceElement }>('PRICE_TOGGLE_SELECTED')
export const changeAmount = actionCreator<{ segment: number, element: PriceElement, amount: number }>('PRICE_CHANGE_AMOUNT')
export const sendPriceRequest = actionCreator<{ email: string, prices: PriceElement[] }>('PRICE_SEND_REQUEST')