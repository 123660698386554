import React from 'react'
import Contact from '~screens/Home/Contact'
import { useDispatch, useSelector } from 'react-redux'
import { getContact, getContactDirty } from '~store/contact/contact.selectors'
import {
  BackendContent,
  BackendHeader,
  BackendRight,
  BackendTitle,
  EditBox,
  PreviewBox, Spacing
} from '~screens/Backend/BackendStyling'
import { Button, TextField } from '@material-ui/core'
import { overwriteContact, saveContact, setDirty } from '~store/contact/contact.actions'

const BackendContact: React.FC = () => {
  const contact = useSelector(getContact)
  const dirty = useSelector(getContactDirty)
  const dispatch = useDispatch()

  const save = () => {
    dispatch(saveContact(contact))
  }

  const editTitle = (event) => {
    dispatch(overwriteContact({
      ...contact,
      title: event.target.value
    }))
    dispatch(setDirty())
  }

  const editSubTitle = (event) => {
    dispatch(overwriteContact({
      ...contact,
      subtitle: event.target.value
    }))
    dispatch(setDirty())
  }

  const editName = (event) => {
    dispatch(overwriteContact({
      ...contact,
      name: event.target.value
    }))
    dispatch(setDirty())
  }

  const editStreet = (event) => {
    dispatch(overwriteContact({
      ...contact,
      street: event.target.value
    }))
    dispatch(setDirty())
  }

  const editZip = (event) => {
    dispatch(overwriteContact({
      ...contact,
      zip: event.target.value
    }))
    dispatch(setDirty())
  }

  const editCity = (event) => {
    dispatch(overwriteContact({
      ...contact,
      city: event.target.value
    }))
    dispatch(setDirty())
  }

  const editPhone = (event) => {
    dispatch(overwriteContact({
      ...contact,
      phone: event.target.value
    }))
    dispatch(setDirty())
  }

  const editMail = (event) => {
    dispatch(overwriteContact({
      ...contact,
      mail: event.target.value
    }))
    dispatch(setDirty())
  }

  const editTelegram = (event) => {
    dispatch(overwriteContact({
      ...contact,
      telegram: event.target.value
    }))
    dispatch(setDirty())
  }

  const editFacebook = (event) => {
    dispatch(overwriteContact({
      ...contact,
      facebook: event.target.value
    }))
    dispatch(setDirty())
  }

  const editInsta = (event) => {
    dispatch(overwriteContact({
      ...contact,
      instagram: event.target.value
    }))
    dispatch(setDirty())
  }

  return (
    <BackendRight>
      <BackendHeader>
        <BackendTitle>Kontakt</BackendTitle>
        <Button
          variant="contained"
          color="primary"
          style={{ width: 300, height: 50, marginTop: 10, marginRight: 30 }}
          onClick={save}
          disabled={!dirty}
        >
          Speichern
        </Button>
      </BackendHeader>

      <BackendContent>
        <EditBox>
          <table>
            <tr>
              <td>Firmenname:</td>
              <td>
                <TextField
                  value={contact.title}
                  variant='outlined'
                  onChange={editTitle}
                  style={{ width: 400 }}
                />
              </td>
            </tr>

            <tr>
              <td>Untertitel:</td>
              <td>
                <TextField
                  value={contact.subtitle}
                  variant='outlined'
                  onChange={editSubTitle}
                  style={{ width: 400 }}
                />
              </td>
            </tr>

            <tr>
              <td>Name:</td>
              <td>
                <TextField
                  value={contact.name}
                  variant='outlined'
                  onChange={editName}
                  style={{ width: 400 }}
                />
              </td>
            </tr>

            <tr>
              <td>Straße:</td>
              <td>
                <TextField
                  value={contact.street}
                  variant='outlined'
                  onChange={editStreet}
                  style={{ width: 400 }}
                />
              </td>
            </tr>

            <tr>
              <td>Postleitzahl:</td>
              <td>
                <TextField
                  value={contact.zip}
                  variant='outlined'
                  onChange={editZip}
                  style={{ width: 400 }}
                />
              </td>
            </tr>

            <tr>
              <td>Stadt:</td>
              <td>
                <TextField
                  value={contact.city}
                  variant='outlined'
                  onChange={editCity}
                  style={{ width: 400 }}
                />
              </td>
            </tr>

            <tr>
              <td>Telefon:</td>
              <td>
                <TextField
                  value={contact.phone}
                  variant='outlined'
                  onChange={editPhone}
                  style={{ width: 400 }}
                />
              </td>
            </tr>

            <tr>
              <td>Mail:</td>
              <td>
                <TextField
                  value={contact.mail}
                  variant='outlined'
                  onChange={editMail}
                  style={{ width: 400 }}
                />
              </td>
            </tr>

            <tr>
              <td>Telegram (Nutzername ohne @):</td>
              <td>
                <TextField
                  value={contact.telegram}
                  variant='outlined'
                  onChange={editTelegram}
                  style={{ width: 400 }}
                />
              </td>
            </tr>

            <tr>
              <td>Facebook (komplette Url inklusive http://):</td>
              <td>
                <TextField
                  value={contact.facebook}
                  variant='outlined'
                  onChange={editFacebook}
                  style={{ width: 400 }}
                />
              </td>
            </tr>

            <tr>
              <td>Instagram (komplette Url inklusive http://):</td>
              <td>
                <TextField
                  value={contact.instagram}
                  variant='outlined'
                  onChange={editInsta}
                  style={{ width: 400 }}
                />
              </td>
            </tr>
          </table>

        </EditBox>

        <BackendTitle>Vorschau</BackendTitle>
        <PreviewBox>
          <Contact contact={contact}/>
        </PreviewBox>
      </BackendContent>
    </BackendRight>
  )
}

export default BackendContact
