import actionCreatorFactory from 'typescript-fsa'

const actionCreator = actionCreatorFactory()

interface userOverwritePayload {
  username: string
  role: string
}

interface loginAttemptPayload {
  username: string
  password: string
}

export const loginAttempt = actionCreator<loginAttemptPayload>('LOGIN_ATTEMPT')
export const loginSuccess = actionCreator<userOverwritePayload>('USER_OVERWRITE')
export const loginFailed = actionCreator<void>('LOGIN_FAILED')
export const loginUserBlocked = actionCreator<void>('LOGIN_USER_BLOCKED')
export const logout = actionCreator<void>('USER_LOGOUT')
export const checkToken = actionCreator<void>('CHECK_TOKEN')
export const changePassword = actionCreator<string>('USER_CHANGE_PASSWORD')