import React, { useState } from 'react'
import {
  BackendContent,
  BackendHeader,
  BackendRight,
  BackendTitle, BoxTitle,
  EditBox,
  PreviewBox
} from '~screens/Backend/BackendStyling'
import { useDispatch, useSelector } from 'react-redux'
import { getAccordion } from '~store/accordion/accordion.selectors'
import Accordion from '~components/Accordion/Accordion'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from '@material-ui/core'
import { AccordionSlide } from '~store/accordion/accordion.iterfaces'
import {
  addAccordionSlide,
  changeAccordionSlide,
} from '~store/accordion/accordion.actions'
import LeistungsSegment from '~screens/Backend/Screens/Leistungen/LeistungsSegment'

export const emptyAccordionSlide: AccordionSlide = {
  id: -1,
  title: '',
  text: ''
}

const BackendLeistungen: React.FC = () => {
  const leistungen = useSelector(getAccordion)
  const dispatch = useDispatch()
  const [currentSlide, setCurrentSlide] = useState({ segment: -1, data: emptyAccordionSlide })
  const [overlayOpen, setOverlayOpen] = useState(false)
  const [newSlide, setNewSlide] = useState(false)
  const [img, setImg] = useState(new Blob())

  const handleFileChange = (event) => {
    setImg(event.target.files[0])
  }

 const saveSlide = () => {
    if (!newSlide) {
      dispatch(changeAccordionSlide(currentSlide))
    } else {
      const data = new FormData()
      data.append('title', currentSlide.data.title)
      data.append('text', currentSlide.data.text)
      data.append('img', img)
      dispatch(addAccordionSlide({ segment: currentSlide.segment, data: data }))
    }
 }

  return (
    <BackendRight>
      <BackendHeader>
        <BackendTitle>Leistungen</BackendTitle>
      </BackendHeader>

      <BackendContent>
        <EditBox>
          <BoxTitle>Hinweis</BoxTitle>
          <span>
            Es gibt aktuell keinen Check auf doppelte Dateinamen! Wenn du also ein neues Bild mit einem
            bereits existierenden Dateinamen hochlädst wird das vorherige Bild überschrieben!
          </span>
        </EditBox>

        <LeistungsSegment segment={0} setCurrentSlide={setCurrentSlide} setOverlayOpen={setOverlayOpen} setNewSlide={setNewSlide} />
        <LeistungsSegment segment={1} setCurrentSlide={setCurrentSlide} setOverlayOpen={setOverlayOpen} setNewSlide={setNewSlide} />
        <LeistungsSegment segment={2} setCurrentSlide={setCurrentSlide} setOverlayOpen={setOverlayOpen} setNewSlide={setNewSlide} />

        <Dialog
          open={overlayOpen}
          onClose={() => setOverlayOpen(false)}
        >
          <DialogTitle>Accordion Segment</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <TextField
                value={currentSlide.data.title}
                variant='outlined'
                label='Titel'
                onChange={(event) => {
                  setCurrentSlide({
                    ...currentSlide,
                    data: {
                      ...currentSlide.data,
                      title: event.target.value
                    }
                  })
                }}
                style={{ width: '100%' }}
              />
              <TextField
                value={currentSlide.data.text}
                label='Text'
                variant='outlined'
                multiline
                onChange={(event) => {
                  setCurrentSlide({
                    ...currentSlide,
                    data: {
                      ...currentSlide.data,
                      text: event.target.value
                    }
                  })
                }}
                style={{ width: '100%', marginTop: 10 }}
              />

              {
                newSlide && (
                  <>
                      <br /><br />
                      <input type='file' name='file' onChange={handleFileChange} accept='image/*' />
                      <br />
                      <span>Auflösung für optimale Darstellung: 900x475 Pixel</span>
                  </>
                )
              }
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                saveSlide()
                setOverlayOpen(false)
              }}
              color="primary"
              disabled={newSlide && img.size === 0}
            >
              Speichern
            </Button>
            <Button onClick={() => setOverlayOpen(false)} color="primary">
              Abbrechen
            </Button>
          </DialogActions>
        </Dialog>

        <BackendTitle>Vorschau</BackendTitle>

        <PreviewBox>
          <Accordion accordion={leistungen}/>
        </PreviewBox>
      </BackendContent>
    </BackendRight>
  )
}

export default BackendLeistungen
