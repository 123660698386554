import React, { useState } from 'react'
import { BackendContent, BackendHeader, BackendRight, BackendTitle, EditBox } from '~screens/Backend/BackendStyling'
import { useDispatch } from 'react-redux'
import {
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Snackbar
} from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { changePassword } from '~store/user/user.actions'
import hash from 'object-hash'
import CloseIcon from '@material-ui/icons/Close'
import BackendContact from '~screens/Backend/Screens/BackendContact'

const BackendSettings: React.FC = () => {
  const dispatch = useDispatch()

  const [showPassword, setShowPassword] = useState(false)
  const [password, setPassword] = useState('')
  const [secondPassword, setSecondPassword] = useState('')

  const [snackbarOpen, setSnackbarOpen] = useState(false)

  const openSnackbar = () => {
    setSnackbarOpen(true)
  }

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setSnackbarOpen(false)
  }

  const onChangePassword = (event) => {
    setPassword(event.target.value)
  }

  const onChangeSecondPassword = (event) => {
    setSecondPassword(event.target.value)
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  return (
    <BackendRight>
      <BackendHeader>
        <BackendTitle>Einstellungen</BackendTitle>
      </BackendHeader>

      <BackendContent>
        <EditBox>
          <FormControl>
            <InputLabel htmlFor='standard-adornment-password'>Passwort</InputLabel>
            <Input
              style={{ width: 300 }}
              type={showPassword ? 'text' : 'password'}
              value={password}
              placeholder='Passwort'
              onChange={onChangePassword}
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>

          <br /><br />

          <FormControl error={password !== secondPassword}>
            <InputLabel htmlFor='standard-adornment-password'>Passwort wiederholen</InputLabel>
            <Input
              style={{ width: 300 }}
              type={showPassword ? 'text' : 'password'}
              value={secondPassword}
              placeholder='Passwort'
              onChange={onChangeSecondPassword}
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />

            {password !== secondPassword && <FormHelperText>Passwörter stimmen nicht überein.</FormHelperText>}
          </FormControl>

          <br /><br />
          <Button
            variant='contained'
            color='primary'
            disabled={password !== secondPassword}
            onClick={() => {
              dispatch(changePassword(hash.MD5(password)))
              setPassword('')
              setSecondPassword('')
              openSnackbar()
            }}
          >
            Speichern
          </Button>
        </EditBox>

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          message='Passwort geändert.'
          action={
            <IconButton size='small' onClick={handleCloseSnackbar}>
              <CloseIcon fontSize='small' />
            </IconButton>
          }
        />
      </BackendContent>
    </BackendRight>
  )
}

export default BackendSettings
