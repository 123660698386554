import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { emptyHTMLState } from '~store/html/html.interfaces'
import {
  fetchAgb,
  fetchDatenschutz,
  fetchImpressum, overWriteAgb,
  overWriteDatenschutz,
  overWriteImpressum
} from '~store/html/html.actions'

export const htmlReducer = reducerWithInitialState(emptyHTMLState)
  .case(fetchImpressum, state => {
    return {
      ...state,
      fetchingImpressum: true
    }
  })
  .case(overWriteImpressum, (state, payload) => {
    return {
      ...state,
      impressum: payload,
      fetchingImpressum: false
    }
  })
  .case(fetchDatenschutz, state => {
    return {
      ...state,
      fetchingDatenschutz: true
    }
  })
  .case(overWriteDatenschutz, (state, payload) => {
    return {
      ...state,
      datenschutz: payload,
      fetchingDatenschutz: false
    }
  })
  .case(fetchAgb, state => {
    return {
      ...state,
      fetchingAGB: true
    }
  })
  .case(overWriteAgb, (state, payload) => {
    return {
      ...state,
      agb: payload,
      fetchingAGB: false
    }
  })