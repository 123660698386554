import React from "react";

import {Link} from "react-router-dom";

const Footer: React.FC = () => {
    return (
        <div className="footer">
            <div className="container--small">
                <div className="footer--links">
                    <span>
                        <Link to='/impressum'>
                            Impressum
                        </Link>
                    </span>
                    <span>
                        <Link to='/datenschutz'>
                            Datenschutz
                        </Link>
                    </span>
                </div>
            </div>
        </div>
    );
};

export default Footer