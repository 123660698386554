import React, { useState } from 'react'
import { BackendContent, BackendHeader, BackendRight, BackendTitle, EditBox } from '~screens/Backend/BackendStyling'
import { useDispatch, useSelector } from 'react-redux'
import { getDatenschutz, getDatenschutzFetching } from '~store/html/html.selectors'
import { Node } from 'slate'
import { Button } from '@material-ui/core'
import { saveDatenschutz } from '~store/html/html.actions'
import RTE from '~screens/Backend/Components/RTE'
import Progress from '~components/Progress/Progress'

const BackendDatenschutz: React.FC = () => {
  const datenschutz = useSelector(getDatenschutz)
  const datenschutzFetching = useSelector(getDatenschutzFetching)
  const [content, setContent] = useState<Node[]>(datenschutz)
  const [dirty, setDirty] = useState(false)
  const dispatch = useDispatch()

  return (
    <BackendRight>
      <BackendHeader>
        <BackendTitle>Datenschutz</BackendTitle>
        <Button
          variant="contained"
          color="primary"
          style={{ width: 300, height: 50, marginTop: 10, marginRight: 30 }}
          disabled={!dirty}
          onClick={() => {
            setDirty(false)
            dispatch(saveDatenschutz(content))
          }}
        >
          Speichern
        </Button>
      </BackendHeader>

      <BackendContent>
        <EditBox>
          {datenschutzFetching ? <Progress /> : <RTE content={content} setContent={setContent} setDirty={setDirty} />}
        </EditBox>
      </BackendContent>
    </BackendRight>
  )
}

export default BackendDatenschutz
