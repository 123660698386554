import { baseUrl } from '~data/config'
import * as path from 'path'
import { put, takeEvery } from 'redux-saga/effects'
import {
    fetchContact,
    overwriteContact, saveContact,
    sendEmail,
    setContactFetchingFinish,
    setContactFetchingStart
} from './contact.actions'

const contactBaseUrl = path.join(baseUrl, 'contact')

function * fetchContactSaga () {
    yield put(setContactFetchingStart())
    const contactJson = yield fetch('https://' + path.join(contactBaseUrl, 'getContact'))
    const res = yield contactJson.json()
    yield put(overwriteContact(res))
    yield put(setContactFetchingFinish())
}

function * sendEmailSaga (action) {
    yield fetch('https://' + path.join(contactBaseUrl, 'sendMail'), {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(action.payload),
    })
}

function * saveContactSaga (action) {
    let token = yield localStorage.getItem('token')
    yield fetch('https://' + path.join(contactBaseUrl, 'setContact'), {
        method: 'POST',
        headers: {
            'token': token,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(action.payload)
    })
}

export function* watchContactSagas () {
    yield takeEvery(fetchContact, fetchContactSaga)
    yield takeEvery(sendEmail, sendEmailSaga)
    yield takeEvery(saveContact, saveContactSaga)
}