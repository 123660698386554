import { baseUrl } from '../../data/config'
import * as path from 'path'
import { put, takeEvery } from 'redux-saga/effects'
import {
    addGalleryImage,
    changeGalleryTitle, deleteGalleryImage,
    fetchGallery, moveDownImage, moveUpImage,
    overwriteGallery,
    setGalleryFetchingFinish,
    setGalleryFetchingStart
} from './gallery.actions'
import { fetchAccordion } from '~store/accordion/accordion.actions'

const galleryBaseUrl = path.join(baseUrl, 'gallery')

function * fetchGallerySaga () {
    yield put(setGalleryFetchingStart())
    const galleryJson = yield fetch('https://' + path.join(galleryBaseUrl, 'getGallery'))
    const res = yield galleryJson.json()
    yield put(overwriteGallery(res))
    yield put(setGalleryFetchingFinish())
}

function * changeGalleryTitleSaga (action) {
    let token = yield localStorage.getItem('token')
    yield fetch('https://' + path.join(galleryBaseUrl, 'changeTitle', String(action.payload.segment)), {
        method: 'POST',
        headers: {
            'token': token,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(action.payload)
    })
}

function * addGalleryImageSaga (action) {
    let token = yield localStorage.getItem('token')
    yield fetch('https://' + path.join(galleryBaseUrl, 'addGalleryImg', String(action.payload.segment)), {
        method: 'POST',
        headers: {
            'token': token,
        },
        body: action.payload.data
    })
    yield put(fetchGallery())
}

function * deleteGalleryImageSaga (action) {
    let token = yield localStorage.getItem('token')
    yield fetch('https://' + path.join(galleryBaseUrl, 'deleteGalleryImg', String(action.payload.segment)), {
        method: 'POST',
        headers: {
            'token': token,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(action.payload)
    })
    yield put(fetchGallery())
}

function * moveUpImageSaga (action) {
    let token = yield localStorage.getItem('token')
    yield fetch('https://' + path.join(galleryBaseUrl, 'moveUp', String(action.payload.segment)), {
        method: 'POST',
        headers: {
            'token': token,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(action.payload)
    })
    yield put(fetchGallery())
}

function * moveDownImageSaga (action) {
    let token = yield localStorage.getItem('token')
    yield fetch('https://' + path.join(galleryBaseUrl, 'moveDown', String(action.payload.segment)), {
        method: 'POST',
        headers: {
            'token': token,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(action.payload)
    })
    yield put(fetchGallery())
}

export function * watchGallerySagas () {
    yield takeEvery(fetchGallery, fetchGallerySaga)
    yield takeEvery(changeGalleryTitle, changeGalleryTitleSaga)
    yield takeEvery(addGalleryImage, addGalleryImageSaga)
    yield takeEvery(deleteGalleryImage, deleteGalleryImageSaga)
    yield takeEvery(moveUpImage, moveUpImageSaga)
    yield takeEvery(moveDownImage, moveDownImageSaga)
}