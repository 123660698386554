import React from 'react';

import 'swiper/css/swiper.css';

import { GalleryState } from '../../store/gallery/gallery.reducer'
import SliderSingleMobile from './SliderSingleMobile'

interface SliderProps {
    gallery: GalleryState
}

const SliderMobile: React.FC<SliderProps> = (props) => {
    return (
        <div className='slidermobile show--mobile'>
            <SliderSingleMobile statusClass={''} images={props.gallery[0].images} segment={0} noPagination />
        </div>
    );
};

export default SliderMobile