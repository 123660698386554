import React, { Dispatch, SetStateAction, useState } from 'react'
import { BoxTitle, EditBox, GalleryButton } from '~screens/Backend/BackendStyling'
import { useDispatch, useSelector } from 'react-redux'
import { getGallery } from '~store/gallery/gallery.selectors'
import { Button, IconButton, TextField } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import {
  changeGalleryTitle,
  deleteGalleryImage,
  moveDownImage,
  moveUpImage,
  overwriteGallery
} from '~store/gallery/gallery.actions'
import * as path from "path"
import { imgBaseUrl } from '~data/config'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

interface GallerySegmentProps {
  segment: number
  openOverlay: () => void
  setSegment: Dispatch<SetStateAction<number>>
}

const GallerySegment: React.FC<GallerySegmentProps> = ({
  segment,
  openOverlay,
  setSegment
}) => {
  const gallery = useSelector(getGallery)
  const dispatch = useDispatch()
  const [dirty, setDirty] = useState(false)

  const galleryBaseUrl = path.join(imgBaseUrl, 'gallery', segment.toString())

  return (
    <EditBox>
      <BoxTitle>
        {gallery[segment].title}
        <IconButton
          onClick={() => {
            openOverlay()
            setSegment(segment)
          }}
        >
          <AddCircleIcon />
        </IconButton>
      </BoxTitle>

      <TextField
        value={gallery[segment].title}
        variant='outlined'
        label='Titel'
        onChange={(event) => {
          const newGal = {...gallery}
          newGal[segment].title = event.target.value
          dispatch(overwriteGallery(newGal))
          setDirty(true)
        }}
        style={{ width: 400 }}
      />
      <Button
        variant='contained'
        color='primary'
        disabled={!dirty}
        onClick={() => {
          dispatch(changeGalleryTitle({ segment: segment, title: gallery[segment].title }))
          setDirty(false)
        }}
        style={{ height: 58, marginLeft: 10 }}
      >
        Speichern
      </Button>

      <br /><br />
      {
        gallery[segment].images.map((img, index) => {
          return (
            <GalleryButton>
              <div><img src={'https://' + path.join(galleryBaseUrl, 'slider', img.img)} alt='' style={{ width: 150 }} /></div>
              <div>{img.caption}</div>
              <div>
                <IconButton
                  onClick={() => dispatch(moveDownImage({ segment: segment, id: img.id }))}
                  disabled={index === 0}
                >
                  <ExpandLessIcon />
                </IconButton>
              </div>
              <div>
                <IconButton
                  onClick={() => dispatch(moveUpImage({ segment: segment, id: img.id }))}
                  disabled={gallery[segment].images.length === index + 1}
                >
                  <ExpandMoreIcon />
                </IconButton>
              </div>
              <div>
                <IconButton
                  onClick={() => {
                    dispatch(deleteGalleryImage({
                      segment: segment,
                      img: img.img
                    }))
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            </GalleryButton>
          )
        })
      }
    </EditBox>
  )
}

export default GallerySegment
