import React from 'react';

import logo from './img/logo.png';
import { Link } from "react-router-dom";

const Header: React.FC = () => {
    return (
        <div>
            <div className='header'>
                <div className='header--content container--small'>
                    <div className='header--logo'>
                        <Link to='/'>
                            <img src={logo} alt='image' />
                        </Link>
                    </div>
                </div>
            </div>
            <div className='header--seperator'>
                <div className='header--seperator--right' />
                <div className='header--seperator--container container--small'>
                    <div className='header--seperator--nav'>
                        <Link to='/leistungen'>Leistungen</Link>
                        <Link to='/profil'>Profil</Link>
                        <Link to='/preise'>Preise</Link>
                        <Link to='/kontakt'>Kontakt</Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header