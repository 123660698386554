import { Node } from 'slate'

export interface HTMLState {
  impressum: Node[]
  datenschutz: Node[]
  agb: Node[]
  fetchingImpressum: boolean
  fetchingDatenschutz: boolean
  fetchingAGB: boolean
}

export const emptyHTMLState: HTMLState = {
  impressum: [],
  datenschutz: [],
  agb: [],
  fetchingImpressum: false,
  fetchingDatenschutz: false,
  fetchingAGB: false,
}