import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getUser } from '~store/user/user.selectors'

const LoginRoute = ({children, ...rest}: any) => {
  const user = useSelector(getUser)

  const to = rest.location.state && rest.location.state.from.pathname ? rest.location.state.from.pathname : '/backend'

  return (
    <Route
      {...rest}
      render={({ location }) =>
        user.loggedIn ? (
          <Redirect
            to={{
              pathname: to,
              state: { from: location }
            }}
          />
        ) : (
          children
        )
      }
    />
  )
}

export default LoginRoute