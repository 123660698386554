import { createSelector } from 'reselect'
import { RootState } from '../root.reducer'
import { Amount, Price, PriceElement } from '~store/prices/prices.interfaces'

const selectPricesState = (state: RootState) => state.prices;

export const getPrices = createSelector(
  selectPricesState,
  state => state.prices
)

export const getPricesFetching = createSelector(
  selectPricesState,
  state => state.fetching
)

export const getSelected = createSelector(
  selectPricesState,
  state => {
    const selected: PriceElement[] = []
    state.prices['0'].prices.forEach(el => {
      if (el.selected) {
        selected.push(el)
      }
    })
    state.prices['1'].prices.forEach(el => {
      if (el.selected) {
        selected.push(el)
      }
    })
    state.prices['2'].prices.forEach(el => {
      if (el.selected) {
        selected.push(el)
      }
    })
    return selected
  }
)

export const getPriceTotal = createSelector(
  selectPricesState,
  state => {
    let selected = 0
    state.prices['0'].prices.forEach(el => {
      if (el.selected) {
        let price = el.data as Price
        selected += price.price
      } else if (el.type === 'amount' && el.amount) {
        let price = el.data as Amount
        selected += el.amount * price.price
      }
    })
    state.prices['1'].prices.forEach(el => {
      if (el.selected) {
        let price = el.data as Price
        selected += price.price
      } else if (el.type === 'amount' && el.amount) {
        let price = el.data as Amount
        selected += el.amount * price.price
      }
    })
    state.prices['2'].prices.forEach(el => {
      if (el.selected) {
        let price = el.data as Price
        selected += price.price
      } else if (el.type === 'amount' && el.amount) {
        let price = el.data as Amount
        selected += el.amount * price.price
      }
    })
    return selected
  }
)