import actionCreatorFactory from 'typescript-fsa'
import { GalleryState } from './gallery.reducer'
import { MoveAccordionPayload } from '~store/accordion/accordion.actions'

const actionCreator = actionCreatorFactory()

export interface changeGalleryTitlePayload {
  segment: number
  title: string
}

export interface addGalleryImagePayload {
  segment: number
  data: FormData
}

export interface deleteGalleryImagePayload {
  segment: number
  img: string
}

export const fetchGallery = actionCreator<void>('GALLERY_FETCH')
export const overwriteGallery = actionCreator<GalleryState>('GALLERY_OVERWRITE')
export const setGalleryFetchingStart = actionCreator<void>('GALLERY_SET_FETCHING_START')
export const setGalleryFetchingFinish = actionCreator<void>('GALLERY_SET_FETCHING_FINISH')
export const changeGalleryTitle = actionCreator<changeGalleryTitlePayload>('GALLERY_CHANGE_TITLE')
export const addGalleryImage = actionCreator<addGalleryImagePayload>('GALLERY_ADD_IMAGE')
export const deleteGalleryImage = actionCreator<deleteGalleryImagePayload>('GALLERY_DELETE_IMAGE')
export const moveUpImage = actionCreator<MoveAccordionPayload>('GALLERY_MOVE_UP')
export const moveDownImage = actionCreator<MoveAccordionPayload>('GALLERY_MOVE_DOWN')