import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { overwriteAccordion, setAccordionFetchingFinish, setAccordionFetchingStart } from './accordion.actions'
import { AccordionSlide } from '~store/accordion/accordion.iterfaces'

interface SingleAccordion {
    title: string
    slides: AccordionSlide[]
}

export interface AccordionState {
    0: SingleAccordion
    1: SingleAccordion
    2: SingleAccordion
    fetching: boolean
}

const INITIAL_STATE: AccordionState = {
    0: {
        title: "Visualisierung",
        slides: []
    },
    1: {
        title: "Grafik Design",
        slides: []
    },
    2: {
        title: "CAD",
        slides: []
    },
    fetching: true
}

export const AccordionReducer = reducerWithInitialState(INITIAL_STATE)
    .case(overwriteAccordion, (state, payload) => {
        return ({
            ...payload,
        })
    })
    .case(setAccordionFetchingStart, (state) => {
        return {
            ...state,
            fetching: true
        }
    })
    .case(setAccordionFetchingFinish, (state) => {
        return {
            ...state,
            fetching: false
        }
    })