import React from 'react'
import { Node, Text } from 'slate'
import escapeHtml from 'escape-html'

interface SlateSerializerProps {
  nodes: Node[]
}

const SlateSerializer: React.FC<SlateSerializerProps> = (props) => {
  const serialize = node => {
    if (Text.isText(node)) {
      if (node.link) {
        return <a className='link' href={node.text}>{node.text}</a>
      } else {
        return escapeHtml(node.text)
      }
    }

    const children = node.children.map(n => serialize(n))

    switch (node.type) {
      case 'h1':
        return <h1 className='font--h1'>{children}</h1>
      case 'h2':
        return <h2 className='font--h2'>{children}</h2>
      case 'h3':
        return <h3 className='font--h3'>{children}</h3>
      case 'p':
        return <p>{children}</p>
      case 'li':
        return <ul><li>{children}</li></ul>
      default:
        return <p>children</p>
    }
  }

  return (
    <>
      {props.nodes.map(e => serialize(e))}
    </>
  )
}

export default SlateSerializer
