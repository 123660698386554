import actionCreatorFactory from 'typescript-fsa'
import { ContactState } from './contact.reducer'
import { Contact } from '~store/contact/contact.interfaces'

const actionCreator = actionCreatorFactory()

export interface EmailData {
    email: string
    subject: string
    content: string
}

export const fetchContact = actionCreator<void>('CONTACT_FETCH')
export const overwriteContact = actionCreator<Contact>('CONTACT_OVERWRITE')
export const setContactFetchingStart = actionCreator<void>('CONTACT_SET_FETCHING_START')
export const setContactFetchingFinish = actionCreator<void>('CONTACT_SET_FETCHING_FINISH')
export const sendEmail = actionCreator<EmailData>('CONTACT_SEND_EMAIL')
export const setDirty = actionCreator<void>('CONTACT_SET_DIRTY')
export const saveContact = actionCreator<Contact>('CONTACT_SAVE_CONTACT')
