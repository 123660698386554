import React, { useState } from 'react'
import {
  BackendContent,
  BackendHeader,
  BackendRight,
  BackendTitle, BoxTitle,
  EditBox,
  PreviewBox
} from '~screens/Backend/BackendStyling'
import { useDispatch, useSelector } from 'react-redux'
import { getGallery } from '~store/gallery/gallery.selectors'
import Slider from '~components/Slider/Slider'
import GallerySegment from '~screens/Backend/Screens/Gallery/GallerySegment'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core'
import { addGalleryImage } from '~store/gallery/gallery.actions'
import Progress from '~components/Progress/Progress'

const BackendGallery: React.FC = () => {
  const gallery = useSelector(getGallery)
  const [overlayOpen, setOverlayOpen] = useState(false)
  const [full, setFull] = useState(new Blob())
  const [slider, setSlider] = useState(new Blob())
  const [caption, setCaption] = useState('')
  const [segment, setSegment] = useState(-1)
  const dispatch = useDispatch()

  const handleFullChange = (event) => {
    setFull(event.target.files[0])
  }

  const handleSliderChange = (event) => {
    setSlider(event.target.files[0])
  }

  const openOverlay = () => {
    setFull(new Blob())
    setSlider(new Blob())
    setCaption('')
    setOverlayOpen(true)
  }

  const saveImage = () => {
    const data = new FormData()
    data.append('full', full)
    data.append('slider', slider)
    data.append('caption', caption)
    dispatch(addGalleryImage({
      segment: segment,
      data: data
    }))
  }

  return (
    <BackendRight>
      <BackendHeader>
        <BackendTitle>Gallerie</BackendTitle>
      </BackendHeader>

      <BackendContent>
        <EditBox>
          <BoxTitle>Hinweis</BoxTitle>
          <span>
            Es gibt aktuell keinen Check auf doppelte Dateinamen! Wenn du also ein neues Bild mit einem
            bereits existierenden Dateinamen hochlädst wird das vorherige Bild überschrieben!
          </span>
        </EditBox>

        <GallerySegment segment={0} openOverlay={openOverlay} setSegment={setSegment} />
        <GallerySegment segment={1} openOverlay={openOverlay} setSegment={setSegment} />
        <GallerySegment segment={2} openOverlay={openOverlay} setSegment={setSegment} />

        <Dialog
          open={overlayOpen}
          onClose={() => setOverlayOpen(false)}
        >
          <DialogTitle>Bild hinzufügen</DialogTitle>
          <DialogContent>
            <span>Bild in voller Auflösung:</span>
            <br />
            <input type='file' name='file' onChange={handleFullChange} accept='image/*' />
            <br />
            <span>Auflösung für optimale Darstellung: 2000x1055 Pixel</span>
            <br /><br />
            <span>Bild in kleinerer Auflösung für den Slider:</span>
            <br />
            <input type='file' name='file' onChange={handleSliderChange} accept='image/*' />
            <br />
            <span>Auflösung für optimale Darstellung: 1067x563 Pixel</span>
            <br /><br />
            <TextField
              value={caption}
              variant='outlined'
              label='Bildunterschrift'
              onChange={(event) => setCaption(event.target.value)}
              style={{ width: '100%' }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                saveImage()
                setOverlayOpen(false)
              }}
              color="primary"
              disabled={full.size === 0 || slider.size === 0}
            >
              Speichern
            </Button>
            <Button onClick={() => setOverlayOpen(false)} color="primary">
              Abbrechen
            </Button>
          </DialogActions>
        </Dialog>

        <BackendTitle>Vorschau</BackendTitle>
        {gallery.fetching ? <Progress />  : <Slider gallery={gallery}/>}
      </BackendContent>
    </BackendRight>
  )
}

export default BackendGallery
