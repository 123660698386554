import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { PriceElement, PriceState } from '~store/prices/prices.interfaces'
import { changeAmount, fetchPrices, overWritePrices, toggleSelected } from '~store/prices/prices.actions'

const INITIAL_STATE: PriceState = {
  fetching: false,
  prices: {
    "0": {prices: [], title: ""},
    "1": {prices: [], title: ""},
    "2": {prices: [], title: ""},
  }
}

const PriceReducer = reducerWithInitialState(INITIAL_STATE)
  .case(fetchPrices, state => {
    return {
      ...state,
      fetching: true
    }
  })
  .case(overWritePrices, (state, payload) => {
    return {
      fetching: false,
      prices: payload
    }
  })
  .case(toggleSelected, ((state, payload) => {
    let price = state.prices[payload.segment].prices.filter(el => el.id === payload.element.id)[0]
    price.selected = price.selected ? false : true
    const stateCopy = {...state}
    const newPrices = state.prices[payload.segment].prices.filter(el => el.id !== payload.element.id)
    newPrices.push(price)
    stateCopy.prices[payload.segment].prices = newPrices

    return stateCopy
  }))
  .case(changeAmount, ((state, payload) => {
    let price = state.prices[payload.segment].prices.filter(el => el.id === payload.element.id)[0]
    price.amount = payload.amount
    const stateCopy = {...state}
    const newPrices = state.prices[payload.segment].prices.filter(el => el.id !== payload.element.id)
    newPrices.push(price)
    stateCopy.prices[payload.segment].prices = newPrices

    return stateCopy
  }))

export default PriceReducer