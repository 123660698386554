import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { fetchAccordion } from '~store/accordion/accordion.actions'
import { fetchContact } from '~store/contact/contact.actions'
import { fetchGallery } from '~store/gallery/gallery.actions'
import { fetchProfile } from '~store/profile/profile.actions'
import { fetchAgb, fetchDatenschutz, fetchImpressum } from '~store/html/html.actions'
import { fetchPrices } from '~store/prices/prices.actions'

const InitialFetch: React.FC = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchAccordion())
        dispatch(fetchContact())
        dispatch(fetchGallery())
        dispatch(fetchProfile())
        dispatch(fetchImpressum())
        dispatch(fetchDatenschutz())
        dispatch(fetchAgb())
        dispatch(fetchPrices())
    }, [])

    return <></>
}

export default InitialFetch