import React, {useRef, useState} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleUp } from '@fortawesome/free-solid-svg-icons'
import AccordionBlock from './AccordionBlock'
import Swiper from 'react-id-swiper'
import * as path from 'path'
import { imgBaseUrl } from '~data/config'
import { AccordionSlide } from '~store/accordion/accordion.iterfaces'

interface AccordionProps {
    title: string,
    slides: AccordionSlide[]
    segment: number
}

const AccordionSingle: React.FC<AccordionProps> = (props) => {
    const accordionImgPath = path.join(imgBaseUrl, 'accordion', props.segment.toString())

    const [opened, setOpened] = useState(false)
    const [height, setHeight] = useState(0);

    const content = useRef<HTMLDivElement>(null);

    const toggleAccordion = () => {
        setOpened(!opened)
        if (content.current) setHeight(opened ? 0 : content.current.scrollHeight)
    };

    window.addEventListener("resize", () => {
        if (content.current) setHeight(!opened ? 0 : content.current.scrollHeight)
    })

    const params = {
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
            renderBullet: function (index: number, className: string) {
                const caption = props.slides[index] ? props.slides[index].title : ''
                return '<span class="' + className + '">' + caption + '</span>';
            },
        },
        rebuildOnUpdate: true
    };

    return <div className={"accordion"}>
        <button className={opened ? "accordion--button accordion--button--active" : "accordion--button"} onClick={toggleAccordion}>
            <p>{props.title}</p>
            <FontAwesomeIcon className={opened ? "accordion--icon" : "accordion--icon accordion--icon--rotate"} icon={faChevronCircleUp} />
        </button>
        <div ref={content} style={{ maxHeight: height }} className={"panel"}>
            <Swiper {...params}>
                {props.slides.sort((a, b) => a.id - b.id).map((slide) => {
                    return <div key={slide.img}>
                        <AccordionBlock content={slide.text} imagepath={path.join(accordionImgPath, slide.img)} align='left' />
                    </div>
                })}
            </Swiper>
        </div>
    </div>;
};

export default AccordionSingle