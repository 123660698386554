import React from 'react'
import { useHistory, useLocation } from 'react-router'
import { BackendLogo, BackendNavbar, NavButton } from '~screens/Backend/BackendStyling'
import logo from '~screens/Backend/logo.png'
import ImageIcon from '@material-ui/icons/Image'
import AccountBoxIcon from '@material-ui/icons/AccountBox'
import MailIcon from '@material-ui/icons/Mail'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import SettingsIcon from '@material-ui/icons/Settings'
import GavelIcon from '@material-ui/icons/Gavel'
import { logout } from '~store/user/user.actions'
import { useDispatch } from 'react-redux'
import { ROUTES } from '~data/routes'
import SecurityIcon from '@material-ui/icons/Security'
import MenuBookIcon from '@material-ui/icons/MenuBook'
import EuroIcon from '@material-ui/icons/Euro'

const BackendNavigation: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  return (
    <BackendNavbar>
      <BackendLogo><a href='/'><img src={logo} alt='logo' /></a></BackendLogo>
      <NavButton
        active={location.pathname === ROUTES.route_backend_gallery}
        onClick={() => history.push(ROUTES.route_backend_gallery)}
      >
        <ImageIcon /><span>Gallerie</span>
      </NavButton>

      <NavButton
        active={location.pathname === ROUTES.route_backend_leistungen}
        onClick={() => history.push(ROUTES.route_backend_leistungen)}
      >
        <AccountBalanceIcon /><span>Leistungen</span>
      </NavButton>

      <NavButton
        active={location.pathname === ROUTES.route_backend_profil}
        onClick={() => history.push(ROUTES.route_backend_profil)}
      >
        <AccountBoxIcon /><span>Profil</span>
      </NavButton>

      <NavButton
        active={location.pathname === ROUTES.route_backend_contact}
        onClick={() => history.push(ROUTES.route_backend_contact)}
      >
        <MailIcon /><span>Kontakt</span>
      </NavButton>

      <NavButton
        active={location.pathname === ROUTES.route_backend_prices}
        onClick={() => history.push(ROUTES.route_backend_prices)}
      >
        <EuroIcon /><span>Preise</span>
      </NavButton>

      <NavButton
        active={location.pathname === ROUTES.route_backend_impressum}
        onClick={() => history.push(ROUTES.route_backend_impressum)}
      >
        <GavelIcon /><span>Impressum</span>
      </NavButton>

      <NavButton
        active={location.pathname === ROUTES.route_backend_datenschutz}
        onClick={() => history.push(ROUTES.route_backend_datenschutz)}
      >
        <SecurityIcon /><span>Datenschutz</span>
      </NavButton>

      <NavButton
        active={location.pathname === ROUTES.route_backend_agb}
        onClick={() => history.push(ROUTES.route_backend_agb)}
      >
        <MenuBookIcon /><span>Agb</span>
      </NavButton>

      <NavButton
        active={location.pathname === ROUTES.route_backend_settings}
        onClick={() => history.push(ROUTES.route_backend_settings)}
      >
        <SettingsIcon /><span>Einstellungen</span>
      </NavButton>

      <NavButton
        style={{ marginTop: 'auto' }}
        onClick={() => dispatch(logout())}
      >
        <ExitToAppIcon /><span>Logout</span>
      </NavButton>

    </BackendNavbar>
  )
}

export default BackendNavigation
