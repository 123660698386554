import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { overwriteGallery, setGalleryFetchingFinish, setGalleryFetchingStart } from './gallery.actions'

export interface ImageWithCaption {
    id: number
    img: string
    caption: string
}

interface SingleGallery {
    title: string
    images: ImageWithCaption[]
}

export interface GalleryState {
    0: SingleGallery
    1: SingleGallery
    2: SingleGallery
    fetching: boolean
}

const INITIAL_STATE: GalleryState = {
    0: {
        title: "Visualisierung",
        images: [{
            id: 1,
            "img": "1.jpg",
            "caption": ""
        }]
    },
    1: {
        title: "Grafik Design",
        images: [{
            id: 1,
            "img": "1.jpg",
            "caption": ""
        }]
    },
    2: {
        title: "CAD",
        images: [{
            id: 1,
            "img": "1.jpg",
            "caption": ""
        }]
    },
    fetching: true,
}

export const GalleryReducer = reducerWithInitialState(INITIAL_STATE)
    .case(overwriteGallery, (state, payload) => {
        return ({
            ...payload,
        })
    })
    .case(setGalleryFetchingStart, (state) => {
        return {
            ...state,
            fetching: true
        }
    })
    .case(setGalleryFetchingFinish, (state) => {
        return {
            ...state,
            fetching: false
        }
    })