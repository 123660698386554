import React, {useState} from 'react';
import Swiper from 'react-id-swiper'
import 'swiper/css/swiper.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faChevronCircleLeft,
    faChevronCircleRight
} from "@fortawesome/free-solid-svg-icons";
import 'react-image-lightbox/style.css';
import { ImageWithCaption } from '../../store/gallery/gallery.reducer'
import * as path from "path"
import { imgBaseUrl } from '../../data/config'

interface Props {
    statusClass: string,
    images: ImageWithCaption[]
    segment: number
    noPagination?: boolean
}

const SliderSingleMobile: React.FC<Props> = (props) => {
    const [swiper, setSwiper] = useState(null);

    const galleryBaseUrl = path.join(imgBaseUrl, 'gallery', props.segment.toString())

    let params = {
        speed: 500,
        loop: true,
        autoplay: {
            delay: 5500,
            disableOnInteraction: false
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true
        },
        getSwiper: setSwiper,
    };

    if (props.noPagination) {
        params = {
            ...params,
            pagination: false
        }
    }

    const toggleHoverMenu = (enter: boolean) => {
        let pagination = document.getElementsByClassName('swiper-pagination');
        let caption = document.getElementsByClassName('swiper--caption');
        if (enter) {
            for (let item of pagination) {
                item.classList.add('swiper--hide')
            }
            for (let item of caption) {
                item.classList.remove('swiper--hide')
            }
        } else {
            for (let item of pagination) {
                item.classList.remove('swiper--hide')
            }
            for (let item of caption) {
                item.classList.add('swiper--hide')
            }
        }
    };

    const onMouseEnter = () => {
        toggleHoverMenu(true);
        if (swiper) swiper.autoplay.stop();
    }

    const onMouseLeave = () => {
        toggleHoverMenu(false);
        if (swiper) swiper.autoplay.start()
    }

    return (
        <div className={props.statusClass} onMouseEnter={() => onMouseEnter()} onMouseLeave={() => onMouseLeave()}>
            <Swiper {...params}>
                {Object.keys(props.images).map(
                    key => {
                        return (
                            <div key={key}>
                                <img src={'https://' + path.join(galleryBaseUrl, 'slider', props.images[parseInt(key)].img)} alt='' />
                                <div className='swiper--caption swiper--hide'>
                                    <FontAwesomeIcon icon={faChevronCircleLeft} className={'swiper--button'} onClick={() => {swiper.slidePrev()}} />
                                    {props.images[parseInt(key)].caption}
                                    <FontAwesomeIcon icon={faChevronCircleRight} className={'swiper--button'} onClick={() => {swiper.slideNext()}} />
                                </div>
                            </div>
                        )
                    })}
            </Swiper>
        </div>
    );
};

export default SliderSingleMobile