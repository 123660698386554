import { RootState } from '~store/root.reducer'
import { createSelector } from 'reselect'

const htmlState = (state: RootState) => state.html

export const getImpressum = createSelector(
  htmlState,
  state => state.impressum
)

export const getImpressumFetching = createSelector(
  htmlState,
  state => state.fetchingImpressum
)

export const getDatenschutz = createSelector(
  htmlState,
  state => state.datenschutz
)

export const getDatenschutzFetching = createSelector(
  htmlState,
  state => state.fetchingDatenschutz
)

export const getAGB = createSelector(
  htmlState,
  state => state.agb
)

export const getAgbFetching = createSelector(
  htmlState,
  state => state.fetchingAGB
)