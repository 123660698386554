import actionCreatorFactory from 'typescript-fsa'
import { AccordionState } from './accordion.reducer'
import { AccordionSlide } from '~store/accordion/accordion.iterfaces'

const actionCreator = actionCreatorFactory()

export interface ChangeAccordionTitlePayload {
  id: number
  title: string
}

export interface AddAccordionSlidePayload {
  segment: number
  data: FormData
}

export interface ChangeAccordionSlidePayload {
  segment: number
  data: AccordionSlide
}

export interface DeleteAccordionPayload {
  segment: number
  delete: number
}

export interface MoveAccordionPayload {
  segment: number
  id: number
}

export const fetchAccordion = actionCreator<void>('ACCORDION_FETCH')
export const overwriteAccordion = actionCreator<AccordionState>('ACCORDION_OVERWRITE')
export const setAccordionFetchingStart = actionCreator<void>('ACCORDION_SET_FETCHING_START')
export const setAccordionFetchingFinish = actionCreator<void>('ACCORDION_SET_FETCHING_FINISH')
export const changeAccordionTitle = actionCreator<ChangeAccordionTitlePayload>('ACCORDION_CHANGE_TITLE')
export const addAccordionSlide = actionCreator<AddAccordionSlidePayload>('ACCORDION_ADD_SLIDE')
export const changeAccordionSlide = actionCreator<ChangeAccordionSlidePayload>('ACCORDION_CHANGE_SLIDE')
export const deleteAccordionSlide = actionCreator<DeleteAccordionPayload>('ACCORDION_DELETE_SLIDE')
export const moveUpAccordion = actionCreator<MoveAccordionPayload>('ACCORDION_MOVE_UP')
export const moveDownAccordion = actionCreator<MoveAccordionPayload>('ACCORDION_MOVE_DOWN')