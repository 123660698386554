import styled from '@emotion/styled'
import theme from '~style/themeVariables'

export const StyledLogin = styled.div`
  background-color: ${theme.colorLightGrey};
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const LoginContainer = styled.div`
  width: 360px;
  background-color: ${theme.colorWhite};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 30px;
`

export const InputWrapper = styled.div`
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const LoginFailedToast = styled.div`
    background-color: ${theme.colorSignal};
    color: ${theme.colorWhite};
    width: 300px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    text-align: center;
`