import React from "react";
import * as path from "path"

interface AccordionblockProps {
    content: string,
    imagepath: string,
    align: string,
}

const AccordionBlock: React.FC<AccordionblockProps> = (props) => {
    let reverse = props.align === "right" ? "" : "accblock--reverse";
    let textReverse = props.align == "right" ? "accblock--left" : "accblock--right";


    return <div className="container">
        <div className={"accblock " + reverse}>
            <div className={textReverse + " accblock--text"}>
                <p dangerouslySetInnerHTML={{ __html: props.content }} />
            </div>
            <div className="accblock--right">
                <img className="accblock--image" src={'https://' + props.imagepath} alt='image' />
            </div>
        </div>
    </div>;
};

export default AccordionBlock