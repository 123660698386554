import { createSelector } from 'reselect'
import { RootState } from '../root.reducer'

const selectProfileState = (state: RootState) => state.profile;

export const getProfile = createSelector(
  selectProfileState,
  profile => profile.profile
)

export const getProfileFetching = createSelector(
  selectProfileState,
  profile => profile.fetching
)

export const getProfileDirty = createSelector(
  selectProfileState,
  profile => profile.dirty
)