import React, { useState } from 'react';

import 'swiper/css/swiper.css';

import SliderSingle from "./SliderSingle";
import { GalleryState } from '../../store/gallery/gallery.reducer'

interface SliderProps {
    gallery: GalleryState
}

const Slider: React.FC<SliderProps> = (props) => {

    const [activeGallery, setActiveGallery] = useState(0);

    return (
        <div className={'container--small hide--mobile'}>
            <div className='gallery-frame--segments'>
                <a
                    onClick={ () => setActiveGallery(0) }
                    className={ activeGallery === 0 ? 'gallery-frame--segments--active' : '' }
                >
                    {props.gallery[0].title}
                </a>
                <a
                    onClick={ () => setActiveGallery(1)}
                    className={ activeGallery === 1 ? 'gallery-frame--segments--active' : '' }
                >
                    {props.gallery[1].title}
                </a>
                <a
                    onClick={ () => setActiveGallery(2)}
                    className={ activeGallery === 2 ? 'gallery-frame--segments--active' : '' }
                >
                    {props.gallery[2].title}
                </a>
            </div>
            <div className='gallery-frame'>
                <SliderSingle statusClass={activeGallery === 0 ? '' : 'gallery--hidden'} images={props.gallery[0].images} segment={0} />
                <SliderSingle statusClass={activeGallery === 1 ? '' : 'gallery--hidden'} images={props.gallery[1].images} segment={1} />
                <SliderSingle statusClass={activeGallery === 2 ? '' : 'gallery--hidden'} images={props.gallery[2].images} segment={2} />
            </div>
        </div>
    );
};

export default Slider