import React from "react";

import AccordionSingle from "./AccordionSingle";

import { AccordionState } from '../../store/accordion/accordion.reducer'

interface AccordionProps {
    accordion: AccordionState
}

const Accordion: React.FC<AccordionProps> = ({ accordion }) => {
    return (
        <div id={'leistungen'} className={"bg-grey padding-top--regular--desktop--only padding-bottom--regular--desktop--only"}>
            <div className={"accordion container"}>
                <AccordionSingle
                    title={accordion[0].title}
                    slides={accordion[0].slides}
                    segment={0}
                />
                <AccordionSingle
                    title={accordion[1].title}
                    slides={accordion[1].slides}
                    segment={1}
                />
                <AccordionSingle
                    title={accordion[2].title}
                    slides={accordion[2].slides}
                    segment={2}
                />
            </div>
        </div>
    );
};

export default Accordion