import React, { Dispatch, SetStateAction, useCallback, useMemo } from 'react'
import { createEditor, Editor, Node, Transforms, Text } from 'slate'
import { Editable, Slate, withReact } from 'slate-react'
import theme from '~style/themeVariables'
import { RteButton } from '~screens/Backend/BackendStyling'

interface RteProps {
  content: Node[]
  setContent: Dispatch<SetStateAction<Node[]>>
  setDirty: Dispatch<SetStateAction<boolean>>
}

const Leaf = props => {
  if (props.leaf.link) {
    return <a className='link' href={props.children} {...props.attributes}>{props.children}</a>
  } else {
    return <span {...props.attributes}>{props.children}</span>
  }
}

const Util = {
  checkType(editor, type) {
    const [match] = Editor.nodes(editor, {
      match: n => n.type === type,
    })

    return !!match
  },

  toggleType(editor, type) {
    const isActive = Util.checkType(editor, type)
    Transforms.setNodes(
      editor,
      { type: isActive ? null : type },
      { match: n => Editor.isBlock(editor, n) }
    )
  },

  isLinkActive(editor) {
    const [match] = Editor.nodes(editor, {
      match: n => n.link === true,
      universal: true,
    })

    return !!match
  },

  toggleLink(editor) {
    const isActive = Util.isLinkActive(editor)
    Transforms.setNodes(
      editor,
      { link: isActive ? null : true },
      { match: n => Text.isText(n), split: true }
    )
  },
}

const RTE: React.FC<RteProps> = (props) => {
  const editor = useMemo(() => withReact(createEditor()), [])

  const renderElement = useCallback(props => {
    switch (props.element.type) {
      case 'h1':
        return <h1 className='font--h1' {...props.attributes}>{props.children}</h1>
      case 'h2':
        return <h2 className='font--h2' {...props.attributes}>{props.children}</h2>
      case 'h3':
        return <h3 className='font--h3' {...props.attributes}>{props.children}</h3>
      case 'p':
        return <p {...props.attributes}>{props.children}</p>
      case 'li':
        return <ul {...props.attributes}><li>{props.children}</li></ul>
      default:
        return <p>props.children</p>
    }
  }, [])

  const renderLeaf = useCallback(props => {
    return <Leaf {...props} />
  }, [])

  return (
    <Slate
      editor={editor}
      value={props.content}
      onChange={content => {
        props.setDirty(true)
        props.setContent(content)
      }}
    >
      <div>
        <RteButton onMouseDown={event => Util.toggleType(editor, 'p')}>Text</RteButton>
        <RteButton onMouseDown={event => Util.toggleType(editor, 'h1')}>Überschrift 1</RteButton>
        <RteButton onMouseDown={event => Util.toggleType(editor, 'h2')}>Überschrift 2</RteButton>
        <RteButton onMouseDown={event => Util.toggleType(editor, 'h3')}>Überschrift 3</RteButton>
        <RteButton onMouseDown={event => Util.toggleType(editor, 'li')}>Listitem</RteButton>
        <RteButton onMouseDown={event => Util.toggleLink(editor)}>Link</RteButton>
      </div>
      <Editable
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        style={{
          padding: '10px',
          border: '1px solid #999',
          borderRadius: '10px'
        }}
      />
    </Slate>
  )
}

export default RTE
