import * as path from 'path'
import { baseUrl } from '~data/config'
import { put, takeEvery } from 'redux-saga/effects'
import {
  fetchAgb,
  fetchDatenschutz,
  fetchImpressum, overWriteAgb,
  overWriteDatenschutz,
  overWriteImpressum, saveAgb, saveDatenschutz,
  saveImpressum
} from '~store/html/html.actions'

const impressumBaseUrl = path.join(baseUrl, 'impressum')
const datenschutzBaseUrl = path.join(baseUrl, 'datenschutz')
const agbBaseUrl = path.join(baseUrl, 'agb')

function* fetchImpressumSaga() {
  const impressumJson = yield fetch('https://' + path.join(impressumBaseUrl, 'getImpressum'))
  const res = yield impressumJson.json()
  yield put(overWriteImpressum(res))
}

function* saveImpressumSaga(action) {
  let token = yield localStorage.getItem('token')
  yield fetch('https://' + path.join(impressumBaseUrl, 'setImpressum'), {
    method: 'POST',
    headers: {
      'token': token,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(action.payload)
  })
  yield put(fetchImpressum())
}

function* fetchDatenschutzSaga() {
  const datenschutzJson = yield fetch('https://' + path.join(datenschutzBaseUrl, 'getDatenschutz'))
  const res = yield datenschutzJson.json()
  yield put(overWriteDatenschutz(res))
}

function* saveDatenschutzSaga(action) {
  let token = yield localStorage.getItem('token')
  yield fetch('https://' + path.join(datenschutzBaseUrl, 'setDatenschutz'), {
    method: 'POST',
    headers: {
      'token': token,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(action.payload)
  })
  yield put(fetchDatenschutz())
}

function* fetchAgbSaga() {
  const agbJson = yield fetch('https://' + path.join(agbBaseUrl, 'getAgb'))
  const res = yield agbJson.json()
  yield put(overWriteAgb(res))
}

function* saveAgbSaga(action) {
  let token = yield localStorage.getItem('token')
  yield fetch('https://' + path.join(agbBaseUrl, 'setAgb'), {
    method: 'POST',
    headers: {
      'token': token,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(action.payload)
  })
  yield put(fetchAgb())
}

export function* watchImpressumSagas () {
  yield takeEvery(fetchImpressum, fetchImpressumSaga)
  yield takeEvery(saveImpressum, saveImpressumSaga)
  yield takeEvery(fetchDatenschutz, fetchDatenschutzSaga)
  yield takeEvery(saveDatenschutz, saveDatenschutzSaga)
  yield takeEvery(fetchAgb, fetchAgbSaga)
  yield takeEvery(saveAgb, saveAgbSaga)
}