import React from 'react'
import { StyledBackend } from '~screens/Backend/BackendStyling'
import { Switch, Route } from 'react-router-dom'
import BackendNavigation from '~screens/Backend/Components/Navigation'
import { ROUTES } from '~data/routes'
import BackendContact from '~screens/Backend/Screens/BackendContact'
import BackendProfil from '~screens/Backend/Screens/BackendProfil'
import BackendLeistungen from '~screens/Backend/Screens/BackendLeistungen'
import BackendGallery from '~screens/Backend/Screens/BackendGallery'
import BackendSettings from '~screens/Backend/Screens/BackendSettings'
import BackendImpressum from '~screens/Backend/Screens/BackendImpressum'
import BackendDatenschutz from '~screens/Backend/Screens/BackendDatenschutz'
import BackendAgb from '~screens/Backend/Screens/BackendAgb'
import BackendPrices from '~screens/Backend/Screens/BackendPrices'

const Backend: React.FC = () => {
  return (
    <StyledBackend>
      <BackendNavigation />

      <Switch>
        <Route path={ROUTES.route_backend_gallery} exact><BackendGallery /></Route>
        <Route path={ROUTES.route_backend_leistungen} exact><BackendLeistungen /></Route>
        <Route path={ROUTES.route_backend_profil} exact><BackendProfil /></Route>
        <Route path={ROUTES.route_backend_contact} exact><BackendContact /></Route>
        <Route path={ROUTES.route_backend_prices} exact><BackendPrices /></Route>
        <Route path={ROUTES.route_backend_impressum} exact><BackendImpressum /></Route>
        <Route path={ROUTES.route_backend_datenschutz} exact><BackendDatenschutz /></Route>
        <Route path={ROUTES.route_backend_agb} exact><BackendAgb /></Route>
        <Route path={ROUTES.route_backend_settings} exaxt><BackendSettings /></Route>
      </Switch>

    </StyledBackend>
  )
}

export default Backend
