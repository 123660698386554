import React, { useEffect, useState } from 'react'
import { InputWrapper, LoginContainer, LoginFailedToast, StyledLogin } from '~screens/Login/LoginStyling'
import { Button, FormControl, IconButton, Input, InputAdornment, InputLabel, TextField } from '@material-ui/core'
import { checkToken, loginAttempt } from '~store/user/user.actions'
import { useDispatch, useSelector } from 'react-redux'
import { getUser } from '~store/user/user.selectors'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import hash from 'object-hash'

const Login: React.FC = () => {
  const dispatch = useDispatch()
  const user = useSelector(getUser)

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const [showPassword, setShowPassword] = useState(false)

  useEffect(() => {
    dispatch(checkToken())
  }, [])

  const onChangeUsername = (event) => {
    setUsername(event.target.value)
  }

  const onChangePassword = (event) => {
    setPassword(event.target.value)
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  }

  const login = () => {
    dispatch(loginAttempt({ username: username, password: hash.MD5(password) }))
  }

  return (
    <StyledLogin>
      <LoginContainer>
        <InputWrapper>
          <TextField
            style={{ width: 300 }}
            value={username}
            label='Username'
            placeholder='Username'
            onChange={onChangeUsername}
          />
        </InputWrapper>

        <InputWrapper>
          <FormControl>
            <InputLabel htmlFor="standard-adornment-password">Passwort</InputLabel>
            <Input
              style={{ width: 300 }}
              type={showPassword ? 'text' : 'password'}
              value={password}
              placeholder='Passwort'
              onChange={onChangePassword}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </InputWrapper>

        {user.failed && <LoginFailedToast>Login fehlgeschlagen.</LoginFailedToast> }
        {user.blocked && <LoginFailedToast>Passwort wurde zu oft falsch eingegeben. Bitte wenden Sie sich an den Administrator.</LoginFailedToast> }

        <InputWrapper>
          <Button
            variant="contained"
            color="primary"
            onClick={login}
            style={{ width: 300 }}
          >
            Login
          </Button>
        </InputWrapper>

      </LoginContainer>
    </StyledLogin>
  )
}

export default Login
