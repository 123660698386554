import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { loginFailed, loginSuccess, loginUserBlocked, logout } from '~store/user/user.actions'


export interface UserState {
  loggedIn: boolean
  username: string
  role: string
  blocked: boolean
  failed: boolean
}

const INITIAL_STATE: UserState = {
  loggedIn: false,
  username: '',
  role: '',
  blocked: false,
  failed: false,
}

export const UserReducer = reducerWithInitialState(INITIAL_STATE)
  .case(loginSuccess, (state, userOverwritePayload): UserState => {
    return {
      ...state,
      ...userOverwritePayload,
      loggedIn: true,
      blocked: false,
      failed: false
    }
  })
  .case(logout, (state): UserState => {
    localStorage.removeItem('token')
    return {
      ...state,
      loggedIn: false,
      username: '',
      role: ''
    }
  })
  .case(loginFailed, (state): UserState => {
    return {
      ...state,
      failed: true
    }
  })
  .case(loginUserBlocked, (state): UserState => {
    return {
      ...state,
      blocked: true
    }
  })