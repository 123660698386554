import React, { useEffect } from "react";
import { faChevronCircleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const BackToTop: React.FC = () => {
    useEffect(() => {
        let myID = document.getElementById("backtotop");

        let myScrollFunc = function() {
            let y = window.scrollY;
            if (myID) {
                myID.className = y >= 500 ? "backtotop backtotop--show" : "backtotop backtotop--hide";
            }
        };

        window.addEventListener("scroll", myScrollFunc);
    });

    return <a className="backtotop" onClick={() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }} id="backtotop">
        <FontAwesomeIcon icon={faChevronCircleUp} />
    </a>;
};

export default BackToTop