import { combineReducers } from 'redux'
import { AccordionReducer, AccordionState } from './accordion/accordion.reducer'
import { ContactReducer, ContactState } from './contact/contact.reducer'
import { GalleryReducer, GalleryState } from './gallery/gallery.reducer'
import { ProfileReducer } from './profile/profile.reducer'
import { UserReducer, UserState } from '~store/user/user.reducer'
import { ProfileState } from '~store/profile/profile.interfaces'
import { HTMLState } from '~store/html/html.interfaces'
import { htmlReducer } from '~store/html/html.reducer'
import { PriceState } from '~store/prices/prices.interfaces'
import PriceReducer from '~store/prices/prices.reducer'

export interface RootState {
    accordion: AccordionState
    contact: ContactState
    gallery: GalleryState
    profile: ProfileState
    user: UserState
    html: HTMLState
    prices: PriceState
}

export const rootReducer = combineReducers<RootState>({
    accordion: AccordionReducer,
    contact: ContactReducer,
    gallery: GalleryReducer,
    profile: ProfileReducer,
    user: UserReducer,
    html: htmlReducer,
    prices: PriceReducer
})