import * as path from 'path'

const hostname = window.location.hostname

let baseUrlBuilder = ''
if (hostname === 'visionaw.de') {
    baseUrlBuilder = baseUrlBuilder + hostname + ':' + 3004
} else if (hostname === 'www.visionaw.de') {
    baseUrlBuilder = baseUrlBuilder + hostname + ':' + 3004
} else if (hostname === 'stage.visionaw.de') {
    baseUrlBuilder = baseUrlBuilder + hostname + ':' + 3003
} else {
    baseUrlBuilder = baseUrlBuilder + hostname + ':' + 3001
}

export const baseUrl = baseUrlBuilder
export const imgBaseUrl = path.join(baseUrl, 'img')