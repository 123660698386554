import { baseUrl } from '../../data/config'
import * as path from 'path'
import {
    addAccordionSlide, changeAccordionSlide,
    changeAccordionTitle, deleteAccordionSlide,
    fetchAccordion, moveDownAccordion, moveUpAccordion,
    overwriteAccordion,
    setAccordionFetchingFinish,
    setAccordionFetchingStart
} from './accordion.actions'
import { put, takeEvery } from 'redux-saga/effects'

const accordionBaseUrl = path.join(baseUrl, 'accordion')

function * fetchAccordionSaga () {
    yield put(setAccordionFetchingStart())
    const accordionJson = yield fetch('https://' + path.join(accordionBaseUrl, 'getAccordion'))
    const res = yield accordionJson.json()
    yield put(overwriteAccordion(res))
    yield put(setAccordionFetchingFinish())
}

function * changeAccordionTitleSaga (action) {
    let token = yield localStorage.getItem('token')
    yield fetch('https://' + path.join(accordionBaseUrl, 'changeTitle', String(action.payload.id)), {
        method: 'POST',
        headers: {
            'token': token,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(action.payload)
    })
}

function* addAccordionSlideSaga(action) {
    let token = yield localStorage.getItem('token')
    yield fetch('https://' + path.join(accordionBaseUrl, 'addSlide', String(action.payload.segment)), {
        method: 'POST',
        headers: {
            'token': token
        },
        body: action.payload.data
    })
    yield put(fetchAccordion())
}

function * changeAccordionSlideSaga(action) {
    let token = yield localStorage.getItem('token')
    yield fetch('https://' + path.join(accordionBaseUrl, 'changeSlide', String(action.payload.segment)), {
        method: 'POST',
        headers: {
            'token': token,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(action.payload.data)
    })
    yield put(fetchAccordion())
}

function * deleteAccordionSlideSaga(action) {
    let token = yield localStorage.getItem('token')
    yield fetch('https://' + path.join(accordionBaseUrl, 'deleteSlide', String(action.payload.segment)), {
        method: 'POST',
        headers: {
            'token': token,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(action.payload)
    })
    yield put(fetchAccordion())
}

function * moveUpAccordionSaga (action) {
    let token = yield localStorage.getItem('token')
    yield fetch('https://' + path.join(accordionBaseUrl, 'moveUp', String(action.payload.segment)), {
        method: 'POST',
        headers: {
            'token': token,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(action.payload)
    })
    yield put(fetchAccordion())
}

function * moveDownAccordionSaga (action) {
    let token = yield localStorage.getItem('token')
    yield fetch('https://' + path.join(accordionBaseUrl, 'moveDown', String(action.payload.segment)), {
        method: 'POST',
        headers: {
            'token': token,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(action.payload)
    })
    yield put(fetchAccordion())
}

export function* watchAccordionSagas () {
    yield takeEvery(fetchAccordion, fetchAccordionSaga)
    yield takeEvery(changeAccordionTitle, changeAccordionTitleSaga)
    yield takeEvery(addAccordionSlide, addAccordionSlideSaga)
    yield takeEvery(changeAccordionSlide, changeAccordionSlideSaga)
    yield takeEvery(deleteAccordionSlide, deleteAccordionSlideSaga)
    yield takeEvery(moveUpAccordion, moveUpAccordionSaga)
    yield takeEvery(moveDownAccordion, moveDownAccordionSaga)
}