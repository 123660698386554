import React from "react";
import { useSelector } from 'react-redux'
import { getImpressum, getImpressumFetching } from '~store/html/html.selectors'
import Progress from '~components/Progress/Progress'
import SlateSerializer from '~components/SlateSerializer/SlateSerializer'

const Impressum: React.FC = () => {
    const impressum = useSelector(getImpressum)
    const impressumFetching = useSelector(getImpressumFetching)
    
    setTimeout(() => {
        let element_to_scroll_to = document.getElementById('root');
        if (element_to_scroll_to) {
            element_to_scroll_to.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });
        }
    }, 100);

    return <div className="container--small color-dark-grey rte">
        <div className='rte--box'>
            {impressumFetching ? <Progress /> : <SlateSerializer nodes={impressum} />}
            <br /><br />
        </div>
    </div>;
};

export default Impressum