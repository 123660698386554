import { all } from 'redux-saga/effects'
import { watchAccordionSagas } from './accordion/accordion.sagas'
import { watchContactSagas } from './contact/contact.sagas'
import { watchGallerySagas } from './gallery/gallery.sagas'
import { watchProfileSagas } from './profile/profile.sagas'
import { watchUserSagas } from '~store/user/user.sagas'
import { watchImpressumSagas } from '~store/html/html.sagas'
import { watchPriceSagas } from '~store/prices/prices.sagas'

export default function* rootSaga () {
  yield all([
    watchAccordionSagas(),
    watchContactSagas(),
    watchGallerySagas(),
    watchProfileSagas(),
    watchUserSagas(),
    watchImpressumSagas(),
    watchPriceSagas()
  ])
}