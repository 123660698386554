import React from 'react'
import { CircularProgress, withStyles } from '@material-ui/core'

const ColoredCircularProgress = withStyles({
    root: {
        color: '#d5b149',
    },
})(CircularProgress);

const Progress: React.FC = () => {
    return <div className='progress-indicator-container'>
        <ColoredCircularProgress size={50} />
    </div>
}

export default Progress