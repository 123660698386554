import React, { useState } from 'react'
import { BackendContent, BackendHeader, BackendRight, BackendTitle, EditBox } from '~screens/Backend/BackendStyling'
import { useDispatch, useSelector } from 'react-redux'
import { getAGB, getAgbFetching } from '~store/html/html.selectors'
import { Node } from 'slate'
import { Button } from '@material-ui/core'
import { saveAgb } from '~store/html/html.actions'
import RTE from '~screens/Backend/Components/RTE'
import Progress from '~components/Progress/Progress'

const BackendAgb: React.FC = () => {
  const agb = useSelector(getAGB)
  const agbFetching = useSelector(getAgbFetching)
  const [content, setContent] = useState<Node[]>(agb)
  const [dirty, setDirty] = useState(false)
  const dispatch = useDispatch()

  return (
    <BackendRight>
      <BackendHeader>
        <BackendTitle>AGB</BackendTitle>
        <Button
          variant="contained"
          color="primary"
          style={{ width: 300, height: 50, marginTop: 10, marginRight: 30 }}
          disabled={!dirty}
          onClick={() => {
            setDirty(false)
            dispatch(saveAgb(content))
          }}
        >
          Speichern
        </Button>
      </BackendHeader>

      <BackendContent>
        <EditBox>
          {agbFetching ? <Progress /> : <RTE content={content} setContent={setContent} setDirty={setDirty} /> }
        </EditBox>
      </BackendContent>
    </BackendRight>
  )
}

export default BackendAgb
