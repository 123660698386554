import actionCreatorFactory from 'typescript-fsa'
import { Node } from 'slate'

const actionCreator = actionCreatorFactory()

export const fetchImpressum = actionCreator<void>('HTML_FETCH_IMPRESSUM')
export const overWriteImpressum = actionCreator<Node[]>('HTML_OVERWRITE_IMPRESSUM')
export const saveImpressum = actionCreator<Node[]>('HTML_SAVE_IMPRESSUM')
export const fetchDatenschutz = actionCreator<void>('HTML_FETCH_DATENSCHUTZ')
export const overWriteDatenschutz = actionCreator<Node[]>('HTML_OVERWRITE_DATENSCHUTZ')
export const saveDatenschutz = actionCreator<Node[]>('HTML_SAVE_DATENSCHUTZ')
export const fetchAgb = actionCreator<void>('HTML_FETCH_AGB')
export const overWriteAgb = actionCreator<Node[]>('HTML_OVERWRITE_AGB')
export const saveAgb = actionCreator<Node[]>('HTML_SAVE_AGB')