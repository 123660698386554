import { reducerWithInitialState } from 'typescript-fsa-reducers'
import {
    overwriteProfile,
    saveProfile,
    setProfileDirty,
    setProfileFetchingFinish,
    setProfileFetchingStart
} from './profile.actions'
import { Profile, ProfileState, SingleCuricula } from '~store/profile/profile.interfaces'

const emptyProfile: Profile = {
    title: '',
    curicula: [],
    freetext: []
}

const INITIAL_STATE: ProfileState = {
    profile: emptyProfile,
    fetching: true,
    dirty: false
}

export const ProfileReducer = reducerWithInitialState(INITIAL_STATE)
    .case(overwriteProfile, (state, payload) => {
        return ({
            ...state,
            profile: payload
        })
    })
    .case(setProfileFetchingStart, (state) => {
        return {
            ...state,
            fetching: true
        }
    })
    .case(setProfileFetchingFinish, (state) => {
        return {
            ...state,
            fetching: false
        }
    })
    .case(setProfileDirty, state => {
        return {
            ...state,
            dirty: true
        }
    })
    .case(saveProfile, state => {
        return {
            ...state,
            dirty: false
        }
    })